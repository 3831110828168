import { Link } from 'react-router-dom';

function ServicesBar({ homeData, servizioId, setServizioId }){
    

    return(
        <>

        <div className='home-text'>{homeData.testo183}</div> 
        <div className="card-columns mt-4 places-container">
            {homeData.servizi.map((servizio, index) =>{
                return(

                    <div key={index} className='d-flex flex-column service-card'>
                        <Link onClick={() => setServizioId(servizio.id)} className="pointer d-flex box-servizio" style={{backgroundImage: "url('"+servizio.copertina+"')", borderRadius: '4px',backgroundSize: 'cover'}}>
                            <div className="col d-flex w-100 flex-column justify-content-between align-self-end">
                                <div className="p-3 justify-content-between row">
                                    <img width={30} src={servizio.icona} />
                                    <span className="text-white align-self-center">{servizio.nome}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            })}

            {/* <div className='d-flex flex-column place-card'>
                <Link to={"/allservices"} className="pointer d-flex border box-servizio">
                    <div className="col d-flex w-100 flex-column justify-content-between align-self-end">
                        <div className="p-3 justify-content-between row">
                        <img width={30} src={others} />
                        <span className="align-self-center">{data.testo97}</span>
                        </div>
                    </div>
                </Link>
            </div> */}

        </div>

        </>
    )
}

export default ServicesBar;