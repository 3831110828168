import React, { useEffect, useState } from 'react';
import '../../functions/openNav'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Fade, Row, Collapse } from 'reactstrap'
import { deleteItem, insertItem, getService, getServiceStatus, getServiceState, getCart, getError, addItem, removeItem } from '../../features/serviceSlice';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { getBootState } from '../../features/bootstrapSlice'
import { getHomeState } from '../../features/homepageSlice';
import Clock from './icons/clock2.svg'
import Phone from './icons/phone.svg'
import Chat from './icons/chat.svg'
import ListaServizi from './ListaServizi'
import ServizioDescrizione from './ServizioDescrizione'


function OverlayServizio(props) {
    const homeData = useSelector(getHomeState);
    
    const [indexCategory, setIndexCategory] = useState(0)

    //orari
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getService("/get-servizio/" + props.match.params.id));
        setIndexCategory(0);
    }, [props.match.params.id]);


    const bootData = useSelector(getBootState);
    const servizio = useSelector(getServiceState);
    const status = useSelector(getServiceStatus);
    const cart = useSelector(getCart);

    return (
        <Fade>
            <ToastContainer className="text-wrap" />
            {!status 
            ?
                <div id="servizi-overlay">
                    {servizio &&
                        <>
                            <div className='' style={{backgroundImage: "url('"+servizio.copertina+"')", backgroundSize: 'cover', height:"250px"}}>
                                <Row className='pt-0 mb-0 ml-0 justify-content-end align-items-start'  style={{background: 'rgba(255,255,255,0.7)',width:"100%"}}>
                                    <Col className='col-10 offset-md-3 pr-0 pl-0 text-center'>
                                        <span className="text-center text-uppercase" style={{ fontSize: "21px", maxWidth: "200px" }}>{servizio.nome}</span>
                                    </Col>
                                    <Col className="col-1 offset-md-3 p-0 align-self-end" style={{fontSize:"22px"}}>
                                        <a onClick={() => props.history.push("/")}>&times;</a>
                                    </Col>
                                </Row>
                            </div>

                            <ServizioDescrizione servizio={servizio}/>

                            <div className='card'>
                                {servizio.orari.giorni && servizio.orari.giorni.length > 0 &&
                                    <div className="card-body border-top border-bottom pt-3 pb-3 mx-1">
                                        <Row className='mx-0'>
                                            <Col className="col-auto d-flex align-items-center">
                                                <img src={Clock} width="20" className="mr-3" alt=""/>
                                                <span className="text-schedule">{servizio.orari.label}</span>
                                            </Col>
                                            <Col className="text-right" onClick={toggle} >
                                                <small className="small">{homeData.testo156} <i className="fas fa-chevron-down"></i></small>
                                            </Col>
                                        </Row>
                                        <Collapse isOpen={isOpen}>
                                            <div className="mt-2">
                                                {servizio.orari.giorni && servizio.orari.giorni.map((giorno, index) =>
                                                    <Row key={index} className="p-1">
                                                        <Col>
                                                            <span className="font-weight-bold">{giorno.giorno}</span>
                                                            <span className="float-right">{giorno.orario}</span>
                                                        </Col>
                                                    </Row>)}
                                            </div>
                                        </Collapse>
                                    </div>
                                }
                                <div className="card-body pl-0 pr-0">
                                    {servizio.info !== "1" &&
                                        <Row className="text-center py-2 bg-white text-dot px-3" style={{ margin: "0px" }}>
                                            {homeData.testo180}
                                        </Row>
                                    }
                                    <Row className="text-center py-2 bg-white" style={{ margin: "0px", overflow:"auto", display:"block", whiteSpace:"nowrap" }}>
                                        {servizio.tipi && servizio.tipi.length > 0 && servizio.tipi.map((tipo, index) =>
                                            <Button key={index} onClick={() => { setIndexCategory(index) }} className={indexCategory === index ? "col btn-secondary text-white w-auto mx-2 cat-serv" : "col btn-light text-white w-auto mx-2 cat-serv" }><span className="m-0">{tipo.nome}</span></Button>
                                        )}
                                    </Row>
                                    <Container className='bg-white px-3'>
                                        {servizio.tipi[indexCategory] && servizio.tipi[indexCategory].elementi.length > 0
                                            ?
                                            servizio.tipi[indexCategory].elementi.map((elemento, index, they) =>
                                                <div key={index} className={they.length - 1 === index ? "pt-3" : "border-bottom pt-3"} style={{marginBottom: they.length - 1 === index ? '2vh' : '' }}>
                                                    <Row>

                                                        <Col className='col font-size-large'>
                                                            <div>{elemento.nome}</div>
                                                        </Col>
                                                        <Col className='col-auto'>
                                                            {elemento.sconto > 0 ?
                                                                <>
                                                                    <span className="price-tag">{"€ " + elemento.sconto.toFixed(2)}</span>
                                                                    <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + elemento.prezzo}</span>
                                                                </>
                                                            :
                                                                <>
                                                                    <span className="price-tag">{"€ " + elemento.prezzo}</span>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="desc-item">{elemento.descrizione}</span>
                                                        </Col>
                                                    </Row>
                                                    {servizio.info !== "1" /*document.cookie.match('connesso')*/
                                                        ?
                                                        <Row className="py-3">
                                                            <Col className="col-6 d-flex">
                                                                {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                    ?
                                                                    <span onClick={() => { dispatch(deleteItem(elemento)); }} className="btn-plus ml-0 text-center">-</span>
                                                                    :
                                                                    <span className="btn-plus ml-0 text-center">-</span>
                                                                }
                                                                <span className="mx-3" style={{ fontSize: "24px" }}>
                                                                    {cart.items[cart.items.findIndex(item => item.id === elemento.id)] ?
                                                                        cart.items[cart.items.findIndex(item => item.id === elemento.id)].quantity
                                                                        :
                                                                        0
                                                                    }
                                                                </span>
                                                                {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                    ?
                                                                    <span onClick={() => dispatch(insertItem(elemento))} className="btn-plus text-center">+</span>
                                                                    :
                                                                    <span className="btn-plus text-center">+</span>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                    ?
                                                                    <Button onClick={() => dispatch(addItem(elemento, this))} className="btn-first w-100">{homeData.testo129}</Button>
                                                                    :
                                                                    <Button onClick={() => dispatch(removeItem(elemento))} className="btn-light w-100">Cancel</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <></>
                                                    }
                                                    {cart.items[cart.items.findIndex(item => item.id === elemento.id)] &&
                                                        cart.items[cart.items.findIndex(item => item.id === elemento.id)].quantity > 0 ?
                                                        <>
                                                            {/* <Row>
                                                                <Col className="col-4">
                                                                    {homeData.testo128}*
                                                                </Col>
                                                            </Row>
                                                            <Row className="pb-3">
                                                                <Col className="col-5 pr-0">
                                                                    {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                        ?
                                                                        <input type="time" className="input-service" placeholder="Dalle" onChange={(e) => dispatch(updateOreDalle({ ore: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreDalle:""}/>
                                                                        :
                                                                        <input type="time" className="input-service" placeholder="Dalle" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreDalle:""} disabled/>
                                                                    }
                                                                </Col>
                                                                <Col className="col pt-2 text-center">-</Col>
                                                                <Col className="col-5 pl-0">
                                                                    {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                        ?
                                                                        <input type="time" className="input-service" placeholder="alle" onChange={(e) => dispatch(updateOreAlle({ ore: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreAlle:""}/>
                                                                        :
                                                                        <input type="time" className="input-service" placeholder="alle" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreAlle:""} disabled/>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="col-4">
                                                                    Data*
                                                                </Col>
                                                            </Row>
                                                            <Row className="pb-3">
                                                                <Col className="col-5 pr-0">
                                                                    {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                        ?
                                                                        <input type="date" className="input-service" placeholder="Dal" onChange={(e) => dispatch(updateGiorniDal({ giorni: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniDal:""}/>
                                                                        :
                                                                        <input type="date" className="input-service" placeholder="Dal" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniDal:""} disabled/>
                                                                    }
                                                                </Col>
                                                                <Col className="col pt-2 text-center">-</Col>
                                                                <Col className="col-5 pl-0">
                                                                    {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                        ?
                                                                        <input type="date" className="input-service" placeholder="al" onChange={(e) => dispatch(updateGiorniAl({ giorni: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniAl:""}/>
                                                                        :
                                                                        <input type="date" className="input-service" placeholder="al" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniAl:""} disabled/>
                                                                    }
                                                                </Col>
                                                            </Row> */}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            )
                                            :
                                            servizio.info === "1"
                                            ?
                                                <>{/* SERVIZIO PRETTAMENTE INFORMATIVO */}
                                                    <Row>
                                                        <Col>
                                                            <i className="fas fas-clock"></i>
                                                        </Col>
                                                        <Col>
                                                            {servizio.sconto > 0 ?
                                                                <>
                                                                    <span className="price-tag">{"€ " + parseFloat(servizio.sconto).toFixed(2)}</span>
                                                                    <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + servizio.prezzo}</span>
                                                                </>
                                                            :
                                                                <>
                                                                    {servizio.prezzo > 0 &&
                                                                        <span className="price-tag">{"€ " + servizio.prezzo}</span>
                                                                    }
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            :
                                            <> {/* SERVIZIO SENZA ELEMENTI CON PRENOTAZIONE*/}
                                                <div key={servizio.id} className="border-bottom mt-3" style={{paddingBottom: servizio.length - 1 === servizio.id ? '50px' : '' }}>
                                                    <Row>
                                                        <Col>
                                                            <i className="fas fas-clock"></i>
                                                        </Col>
                                                        <Col>
                                                            {servizio.sconto > 0 ?
                                                                <>
                                                                    <span className="price-tag">{"€ " + parseFloat(servizio.sconto).toFixed(2)}</span>
                                                                    <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + servizio.prezzo}</span>
                                                                </>
                                                            :
                                                                <>
                                                                    <span className="price-tag">{"€ " + servizio.prezzo}</span>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    {true /*document.cookie.match('connesso')*/ &&
                                                    <>
                                                        <Row className="py-3">
                                                            {servizio.ricqua === '1' && //SERVIZIO PRENOTABILE CON QUANTITÀ
                                                            <Col className="col-6 d-flex">
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <span onClick={() => { dispatch(deleteItem(servizio)); }} className="btn-plus ml-0 text-center">-</span>
                                                                    :
                                                                    <span className="btn-plus ml-0 text-center">-</span>}
                                                                <span className="mx-3" style={{ fontSize: "24px" }}>
                                                                    {cart.items[cart.items.findIndex(item => item.id === servizio.id)] ?
                                                                        cart.items[cart.items.findIndex(item => item.id === servizio.id)].quantity
                                                                        :
                                                                        0}
                                                                </span>
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <span onClick={() => dispatch(insertItem(servizio))} className="btn-plus text-center">+</span>
                                                                    :
                                                                    <span className="btn-plus text-center">+</span>
                                                                }
                                                            </Col>
                                                            }
                                                            <Col>
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <Button onClick={() => dispatch(addItem(servizio))} className="btn-first w-100">{homeData.testo129}</Button>
                                                                    :
                                                                    <Button onClick={() => dispatch(removeItem(servizio))} className="btn-light w-100">Cancel</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col className="col-4">
                                                                {homeData.testo128}*
                                                            </Col>
                                                        </Row>
                                                        <Row className="pb-3">
                                                            <Col className="col-5 pr-0">
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <input type="time" className="input-service" placeholder="Dalle" onChange={(e) => dispatch(updateOreDalle({ ore: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreDalle:""}/>
                                                                    :
                                                                    <input type="time" className="input-service" placeholder="Dalle" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreDalle:""} disabled/>
                                                                }
                                                            </Col>
                                                            <Col className="col pt-2 text-center">-</Col>
                                                            <Col className="col-5 pl-0">
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <input type="time" className="input-service" placeholder="alle" onChange={(e) => dispatch(updateOreAlle({ ore: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreAlle:""}/>
                                                                    :
                                                                    <input type="time" className="input-service" placeholder="alle" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreAlle:""} disabled/>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="col-4">
                                                                Data*
                                                            </Col>
                                                        </Row>
                                                        <Row className="pb-3">
                                                            <Col className="col-5 pr-0">
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <input type="date" className="input-service" placeholder="Dal" onChange={(e) => dispatch(updateGiorniDal({ giorni: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniDal:""}/>
                                                                    :
                                                                    <input type="date" className="input-service" placeholder="Dal" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniDal:""} disabled/>
                                                                }
                                                            </Col>
                                                            <Col className="col pt-2 text-center">-</Col>
                                                            <Col className="col-5 pl-0">
                                                                {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                                    ?
                                                                    <input type="date" className="input-service" placeholder="al" onChange={(e) => dispatch(updateGiorniAl({ giorni: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniAl:""}/>
                                                                    :
                                                                    <input type="date" className="input-service" placeholder="al" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniAl:""} disabled/>
                                                                }
                                                            </Col>
                                                        </Row> */}
                                                    </>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {/* INFORMATIVA UTENTE NON AUTENTICATO */}
                                        {/* {!document.cookie.match('connesso') &&
                                            <>
                                                <h6 className="my-3 text-center">{homeData.testo109}</h6>
                                                <Link to={{pathname: '/login', query: { route: 'login' } }} className="p-3 btn w-100 text-white font-weight-bold btn-first">Login</Link>
                                            </>
                                        } */}
                                    </Container>
                                </div>
                            </div>


                            <div className='card card-body mb-2 mt-2'>
                                <div className='pb-3'><h5>{homeData.testo187}</h5></div>
                                <Row className="text-center mr-0 ml-0">
                                    {bootData.posto_master.telefono !== "" &&
                                        <Col className="p-0 mr-2"><a href={"tel:"+bootData.posto_master.telefono} className="btn btn-custom text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col>
                                    }
                                    {bootData.posto_master.email !== "" &&
                                        <Col className="p-0"><a href={"mailto:"+bootData.posto_master.email} className="btn btn-custom text-white"><img src={Chat} width="20" className="float-left" alt=""/>Email</a></Col>
                                    }
                                    {bootData.posto_master.latitudine !== "0" && bootData.posto_master.longitudine !== "0" &&
                                        <Col className="p-0 ml-2"><a href={"https://maps.google.com/maps?daddr="+bootData.posto_master.latitudine+","+bootData.posto_master.longitudine+"&amp;ll="} target="_blank" rel="noreferrer" className="btn btn-custom text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col>
                                    }
                                </Row>
                            </div>
                        <div className='card-body'>
                            <div className='px-2 pb-4'><p>{homeData.testo186}</p></div>
                            <ListaServizi homeData={homeData}/>
                        </div>
                        <div style={{height: '70px'}} ></div>
                        </>
                    }
                </div>
            :
            <>
                <div className="d-flex justify-content-center align-items-center w-100" style={{height: "80vh"}}>
                    <div className="d-flex flex-column align-items-center">
                        {/* <img style={{height:"150px", width:"250px"}} src={Footer} /> */}
                        <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </>
            }
        </Fade>
    )

}

export default OverlayServizio