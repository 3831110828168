import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setSubAnswers, setAnswers } from '../../features/questSlice';

function Risposta(props){
    const dispatch = useDispatch();

    const updateField = e =>{

        let answer = {}
        if(e.target.dataset.status === 'unck' && e.target.dataset.questiontype === 'RO'){
            e.target.dataset.status = 'ck';
            answer = {id: e.target.name, vote: e.target.id, qt: e.target.dataset.questiontype, textAnswer: 'ck'};
        }else if(e.target.dataset.status === 'ck' && e.target.dataset.questiontype === 'RO') {
            e.target.dataset.status = 'unck';
            answer = {id: e.target.name, vote: e.target.id, qt: e.target.dataset.questiontype, textAnswer: 'unck'};
        }else{
            answer = {id: e.target.name, vote: e.target.id, qt: e.target.dataset.questiontype, textAnswer: e.target.dataset.answer};
        }

        dispatch(setSubAnswers(answer));


        dispatch(setAnswers({name: "opzione"+e.target.name, vote: e.target.id}));
        
    }


    switch(props.domanda.tipovoto){
        case 'SN':
            return(
                <Form>
                {props.domanda.opzioni.map((opzione)=>{
                    return(
                    <FormGroup style={{paddingRight: "3%", marginTop:"2%"}}id={props.domanda.doid} check inline>
                        <Label style={{marginLeft: "25px", display: "flex"}} className="label checkbox checkbox-label" check>
                        <Input name={props.domanda.doid} id={opzione.opid} onChange={updateField} data-questiontype="SN" data-answer={opzione.optesto} type="radio"/><div>{opzione.optesto}</div>
                        <span className="checkmark"></span>
                        </Label>
                    </FormGroup>
                    )
                })}
                </Form>
            );
        default:
            return(
                    <Form>
                    <div className="col">
                    {props.domanda.opzioni.map((opzione)=>{
                        return(
                        <FormGroup  style={{paddingTop: "1%", paddingBottom: "1%"}}  check>
                            <Label  style={{ display: "flex", alignItems:"center"}} className="label checkbox checkbox-label" check>
                            <Input name={props.domanda.doid} id={opzione.opid} onChange={updateField} data-questiontype="RO" data-status="unck" type="radio" /><div>{opzione.optesto}</div>
                            <span  className="checkmark"></span>
                            </Label>
                        </FormGroup>
                        )
                    })}
                    </div>
                    </Form>
            );
    }
}

export default Risposta;