import React, { useState } from 'react';
import { Col, Row, Collapse, Spinner } from 'reactstrap'
import Camera from '../Mobile/icons/camera.svg'
import Switch from 'react-ios-switch'
import { getCheckinState } from '../../features/checkinSlice'
import { getHomeState } from '../../features/homepageSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Ospite(props) {

    const checkinState = useSelector(getCheckinState);
    const homeData = useSelector(getHomeState);

    const [open, setOpen] = useState(false);

    const [foto1, setFoto1] = useState(false);
    const [foto1loading, setFoto1loading] = useState(false);
    const [foto2, setFoto2] = useState(false);
    const [foto2loading, setFoto2loading] = useState(false);

    //const [foto3, setFoto3] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [isCheckedMarketing, setIsCheckedMarketing] = useState(false);

    const uploadFoto = async (foto, nome) => {

        //controllo dimensione file
        const fileSize = foto[0].size / 1024 / 1024; // in MiB
        if (fileSize > 29) {
            alert("L'immagine non deve superare i 30MB!");
        } else {
            var data = new FormData();
            data.append('action', 'checkin')
            data.append('foto', foto[0])

            switch(nome){
                case 'foto1':
                    setFoto1loading(true)
                    break;
                case 'foto2':
                    setFoto2loading(true)
                    break;
                case 'foto3':
                    //setFoto3(await response.json())
                    break;
                default:
                    break
            }

            let response = await fetch('/auto_act.php', {
                method: "POST",
                headers: {
                    mode: 'cors'
                },
                body: data
            })

            switch(nome){
                case 'foto1':
                    setFoto1loading(false)
                    setFoto1(await response.json())
                    break;
                case 'foto2':
                    setFoto2loading(false)
                    setFoto2(await response.json())
                    break;
                case 'foto3':
                    //setFoto3(await response.json())
                    break;
                default:
                    break
            }
        }
    }

    const deleteFoto = async (foto, nome) => {

        var data = new FormData();
        data.append('action', 'delfoto')
        data.append('foto', foto)

        let response = await fetch('/auto_act.php', {
            method: "POST",
            headers: {
                mode: 'cors'
            },
            body: data
        })

        switch(nome){
            case 'foto1':
                setFoto1(false)
                break;
            case 'foto2':
                setFoto2(false)
                break;
            case 'foto3':
                //setFoto3(false)
                break;
            default:
                break
        }
    }

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <hr />
                </Col>
                <Col className="text-center">
                    {homeData.testo155} {props.number} {props.number === 1 ? <></> : <span onClick={props.close()} style={{color: 'red'}}>&#10006;</span>}
                </Col>
                <Col>
                    <hr />
                </Col>
            </Row>
            {props.data && "utente" in props.data.data
            ?
            <div className="row">
                <div className="col">
                    <input className="input-service my-2 nome" placeholder={checkinState.data.testo24} style={{ borderColor: "#f5f5f5" }} defaultValue={props.data.data.utente.nome} required />
                    <input className="input-service my-2 cognome" placeholder={checkinState.data.testo15} style={{ borderColor: "#f5f5f5" }} defaultValue={props.data.data.utente.cognome} required />
                </div>
                <div className="col">
                    <input className="input-service my-2 email" placeholder="Email" style={{ borderColor: "#f5f5f5" }} defaultValue={props.data.data.utente.email} required />
                    <input className="input-service my-2 telefono" placeholder={checkinState.data.testo13} style={{ borderColor: "#f5f5f5" }} defaultValue={props.data.data.utente.telefono} required />
                </div>
            </div>
    
            :
                <div className="row">
                    <div className="col">
                        <input className="input-service my-2 nome" placeholder={checkinState.data.testo24} style={{ borderColor: "#f5f5f5" }} required />
                        <input className="input-service my-2 cognome" placeholder={checkinState.data.testo15} style={{ borderColor: "#f5f5f5" }} required />
                    </div>
                    <div className="col">
                        <input className="input-service my-2 email" placeholder="Email" style={{ borderColor: "#f5f5f5" }} required />
                        <input className="input-service my-2 telefono" placeholder={checkinState.data.testo13} style={{ borderColor: "#f5f5f5" }} required />
                    </div>
                </div>
          
            }
            <Row className="mt-3">
                <Col>
                    <hr />
                </Col>
                <Col className="text-center">
                    <span className="text-nowrap">{checkinState.data.testo14}</span>
                </Col>
                <Col>
                    <hr />
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="text-center p-0">
                    {foto1 ?
                        <>
                            <label><span onClick={() => deleteFoto(foto1, 'foto1')} className="icon-delete-img pointer">&#10006;</span><img src={'/'+foto1} width="86" alt=""/></label>
                            <input type="hidden" value={foto1} className="foto1"/>
                        </>
                    :
                        <>
                            {foto1loading ?
                                <Spinner style={{ width: '2rem', height: '2rem' }} children={false} />
                            :
                                <>
                                    <input type="hidden" value={foto1} className="foto1"/>
                                    <input onChange={(e) => uploadFoto(e.target.files, e.target.name)} type="file" id={'foto1'+props.number} name="foto1" className="w-100 d-none" />
                                    <label htmlFor={'foto1'+props.number} className="mb-0 px-4 py-4 border pointer"><img src={Camera} alt=""/></label>
                                </>
                            }
                        </>
                    }
                </Col>
                <Col className="text-center p-0">
                    {foto2 ?
                        <>
                            <label><span onClick={() => deleteFoto(foto2, 'foto2')} className="icon-delete-img pointer">&#10006;</span><img src={'/'+foto2} width="86" alt=""/></label>
                            <input type="hidden" value={foto2} className="foto2"/>
                        </>
                    :
                        <>
                            {foto2loading ?
                                <Spinner style={{ width: '2rem', height: '2rem' }} children={false} />
                            :
                                <>
                                    <input type="hidden" value={foto2} className="foto2"/>
                                    <input onChange={(e) => uploadFoto(e.target.files, e.target.name)} type="file" id={'foto2'+props.number} name="foto2" className="w-100 d-none" />
                                    <label htmlFor={'foto2'+props.number} className="mb-0 px-4 py-4 border pointer"><img src={Camera} alt=""/></label>
                                </>
                            }
                        </>
                    }
                </Col>
                {/*
                <Col className="text-center p-0">
                    {foto3 ?
                        <>
                            <label><span onClick={() => deleteFoto(foto3, 'foto3')} className="icon-delete-img pointer">&#10006;</span><img src={foto3} width="86" alt=""/></label>
                            <input type="hidden" value={foto3} className="foto3"/>
                        </>
                    :
                        <>
                            <input type="hidden" value={foto3} className="foto3"/>
                            <input onChange={(e) => uploadFoto(e.target.files, e.target.name)} type="file" id={'foto3'+props.number} name="foto3" className="w-100 d-none" />
                            <label htmlFor={'foto3'+props.number} className="mb-0 px-4 py-4 border pointer"><img src={Camera} alt=""/></label>
                        </>
                    }
                </Col>
                */}
            </Row>
            <Row className="mt-3">
                <Col className="d-flex align-items-center">
                    <Link to={'/'+props.master+'/privacy.html'} className='text-link'>Privacy Policy</Link>
                </Col>
                <Col>
                    <Switch
                        checked={isCheckedPrivacy}
                        onChange={() => { setIsCheckedPrivacy(!isCheckedPrivacy) }}
                        className="d-flex float-right"
                        style={{ transform: "scale(0.8)" }}
                        offColor="#37363b"
                        required
                    />
                    <input type="hidden" value={isCheckedPrivacy} className="privacy"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="d-flex align-items-center">
                    <Link to={'/'+props.master+'/marketing.html'} className='text-link'>Marketing Policy</Link>
                </Col>
                <Col>
                    <Switch
                        checked={isCheckedMarketing}
                        onChange={() => { setIsCheckedMarketing(!isCheckedMarketing) }}
                        className="d-flex float-right"
                        style={{ transform: "scale(0.8)" }}
                        offColor="#37363b"
                    />
                    <input type="hidden" value={isCheckedMarketing} className="marketing"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="d-flex align-items-center">
                    <label htmlFor="fattura">{checkinState.data.testo17}</label>
                </Col>
                <Col>
                    <Switch
                        className="fattura d-flex float-right"
                        aria-controls="fattura"
                        aria-expanded={open}
                        checked={open}
                        onChange={() =>  setOpen(!open) }
                        style={{ transform: "scale(0.8)" }}
                        offColor="#37363b"
                    />
                </Col>
            </Row>
            <Collapse isOpen={open}>
                <div id="fattura" className="fattura" value={open}>
                    <input className="input-service my-2 ragionesociale" placeholder={checkinState.data.testo18} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 partitaiva" placeholder={checkinState.data.testo19} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 indirizzo" placeholder={checkinState.data.testo21} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 cap" placeholder={checkinState.data.testo22} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 comune" placeholder={checkinState.data.testo23} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 codice" placeholder={checkinState.data.testo20} style={{ borderColor: "#f5f5f5" }} required />
                    <input className="input-service my-2 pec" placeholder="E-Mail PEC" style={{ borderColor: "#f5f5f5" }} required />
                </div>
            </Collapse>
        </>
    )
}

export default Ospite