import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Modal , Button , ModalHeader , ModalBody , ModalFooter} from 'reactstrap'
import ChevronDown from './icons/chevron_down.svg'

function ServizioDescrizione({servizio}) {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
            <>
            <div className='p-0' onClick={toggle}>
                {servizio.descrizione !== "" &&
                    servizio.descrizione.length < 150 ?
                    <>
                        <div className='m-2'>{parse(servizio.descrizione)}</div>
                    </>
                    :
                    <>
                        <div className='mx-2 m-2' style={{height: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{parse(servizio.descrizione)}</div>
                        <div className='d-flex justify-content-center pb-2 pt-1' style={{boxShadow: 'rgb(255, 255, 255) 0px 0px 20px 20px', background: 'white', position: 'relative'}}><img src={ChevronDown} alt=''></img></div>
                    </>  
                }
            </div>

            <Modal isOpen={modal} centered toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {servizio.nome}
                </ModalHeader>
                <ModalBody>
                    {parse(servizio.descrizione === "" ? "" : servizio.descrizione)}
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={toggle} style={{border:"none",borderRadius:"0.5vh"}}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
        )
}

export default ServizioDescrizione