import React from "react";
import { getDistance } from '../../functions/getDistance';
import { useHistory } from 'react-router-dom';
import pinmap from '../Mobile/icons/pinmap2.svg';


function PlaceCard({homeData, posto, geo}){

    const history = useHistory();
     console.log(posto)
    return(
        <div className="d-flex flex-column place-card">
            <div onClick={() =>history.push("/place/"+posto.id)} className="place-card-img pointer" style={{backgroundImage:'url("'+posto.copertina+'")'}}>
                <div className="col d-flex w-100 flex-column justify-content-between">
                    <div className="p-3 justify-content-between row">
                        {/* <div className="align-items-center d-flex place-fav">
                            <div className="fa-heart-desk fas pr-4"></div>
                            <div className="d-flex">{posto.cuori}</div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="align-items-center d-flex mt-2" style={{fontSize: '12px'}}>
                {posto.orari ? 
                <div className="text-white px-2" style={{background: '#06D6A0', borderRadius: '2px'}}>{homeData.testo181}</div> 
                : 
                <div className="text-white px-2" style={{background: '#D4D4D5', borderRadius: '2px'}}>{homeData.testo182}</div> 
                }
                <div className="ml-2">{posto.categoria}</div>
            </div>
            <div className="row align-items-center d-flex">
                <div className="col place-text">
                    {posto.nome}
                </div>
                <div className="col-auto">
                    {posto.costo ? posto.costo.replace(/&euro;/g,"€") : ""}
                </div>
            </div>
            <div className="row align-items-center d-flex">
                <div style={{fontSize: '12px'}} className="col">
                    <img width={12} src={pinmap}/> {posto.indirizzo}
                </div>
                <div className="col-auto">
                    {posto.latitudine !== 0 && posto.longitudine !== 0 && geo.lat !== 0 &&
                        <>{Math.round(getDistance(posto.latitudine, posto.longitudine, geo.lat, geo.lng)*10)/10} <span className="ml-1">km</span></>
                    }
                </div>
            </div>

        </div>
    )
}

export default PlaceCard;