import React, { useState, useEffect } from 'react';
import CarousInfo from './CarousInfo';
import { Button, Col, Collapse, Fade, Row, Spinner, Modal } from 'reactstrap'
import { getPlace, getPlaceState, getFullPlaceState } from '../../features/placeSlice';
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserState } from '../../features/userSlice'
import { getHomeState } from '../../features/homepageSlice';
import getCookie from '../../functions/getCookie';
import Phone from './icons/phone.svg'
import Map from './icons/Map.svg'
import Web from './icons/Web2.svg'
import Clock from './icons/clock.svg'
import Heart from './icons/heart.svg'
import HeartNo from './icons/Like cuore.svg'
import ChevronDown from './icons/chevron_down.svg'
import ChevronUp from './icons/chevron_up.svg'
import { getDistanceFormat } from '../../functions/getDistance'
import { getGeolocationState } from '../../features/geolocationSlice'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function Place(props) {

    const homeData = useSelector(getHomeState);
    //const [camera, setCamera] = useState('')
    var camera = "";
    var nome = "";
    var cognome = "";
    var email = "";
    const [dataDal, setDataDal] = useState('')
    const [dataAl, setDataAl] = useState('')
    const [quantita, setQuantita] = useState(0)
    const [oraDa, setOraDa] = useState('...')
    const [oraA, setOraA] = useState('...')

    const geo = useSelector(getGeolocationState)

    const [isOpen, setIsOpen] = useState(false);
    const [isOverlay, setIsOverlay] = useState(false);

    const [postLoading, setPostLoading] = useState(false)

    const toggle = () => setIsOpen(!isOpen);
    //interfaccia prenotazione
    const toggleOverlay = () => {
        setIsOverlay(!isOpen)
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlace("/get-posto/" + props.match.params.id));
    }, [props.match.params.id]);

    const selector = useSelector(getPlaceState);
    const status = useSelector(getFullPlaceState)
    const posto = selector.posto
    const categoria = selector.posto ? selector.categorie[0].nome : ""

    //dati utente loggato
    const userData = useSelector(getUserState);

    //gestione like
    const [likes, setLikes] = useState(false);
    var likess = false;
    if (!userData.loading && selector.posto) {
        if("utente" in userData.data)
            userData.data.utente.preferiti.forEach(element => {
                if(element.id === posto.id)
                    likess = true
            });
    }
    
    const submitOrder = async () => {
        setPostLoading(true)
        camera = document.getElementById('camera').value;
        nome = document.getElementById('nome').value;
        cognome = document.getElementById('cognome').value;
        email = document.getElementById('email').value;

        if(camera !== "" && email !== "" && dataDal !== "" && oraDa !== "..." && quantita > 0){

            var dataita = dataDal.split('-');
            var dataitaAl = dataAl.split('-');

            if(dataAl !== ""){
                var dataint = dataita[2]+'/'+dataita[1]+'/'+dataita[0]+" - "+dataitaAl[2]+'/'+dataitaAl[1]+'/'+dataitaAl[0]
            }else {
                var dataint = dataita[2]+'/'+dataita[1]+'/'+dataita[0]+" - ..."
            }
                
            let obj = {
                utente: getCookie('userkey'),
                camera: camera,
                nome: nome,
                cognome: cognome,
                email: email,
                giorni: dataint,
                quantita: quantita,
                ore: oraDa + " - " + oraA,
                poid: props.match.params.id
            }

            let response = await fetch('/prenota_act.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            })
            const json = await response.json()
            //console.log(await response.json())
            setPostLoading(false)
            props.history.push("/thankyou")
        }else{
            setPostLoading(false)
            var elm
            if(camera === '') {
                elm = document.getElementById('camera');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("camera").scrollIntoView();
            }else {
                elm = document.getElementById('camera');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(email === '') {
                elm = document.getElementById('email');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("email").scrollIntoView();
            }else {
                elm = document.getElementById('email');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(dataDal === "") {
                elm = document.getElementById('data');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("data").scrollIntoView();
            }else {
                elm = document.getElementById('data');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(oraDa === "...") {
                elm = document.getElementById('ora');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("ora").scrollIntoView();
            }else {
                elm = document.getElementById('ora');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(quantita === 0) {
                elm = document.getElementById('quantita');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("quantita").scrollIntoView();
            }else {
                elm = document.getElementById('quantita');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
        }
    }

    const like = async (posto) => {

        if(getCookie('connesso')) {
            var data = new FormData();
            data.append('action', 'like')
            data.append('posto', posto)
            data.append('ospite', getCookie('userkey'))

            setLikes(!likes)
            likess = like
            
            fetch('/auto_act.php', {
                method: "POST",
                headers: {
                    mode: 'cors'
                },
                body: data
            })
        } else {
            //effettua il login
            toast.info(homeData.testo108)
        }
        
    }   

    return (
        <Fade>

            {!status.loading ?
                <>
                    <ToastContainer className="text-wrap" />
                    {posto &&
                        <>
                            <div className="btn-times">
                                <Link to={{pathname: props.history.location?.state ? props.history.location.state.prevPath : '/', query: props.location.query}} className="btn-times-icon d-block text-center">&times;</Link>
                            </div>
                            <div className="car-place">
                                <CarousInfo img={selector.photos} />
                            </div>
                            {props.history.location?.state && props.history.location.state.page === 'itinerary'
                            ?
                                <>
                                </>
                            :
                                <>
                                <Row className="mx-1">
                                    <Col className="col-8 mt-2">
                                        <h4 className="mt-2 mb-0 font-weight-bold">{posto.nome}</h4>
                                        <p className="card-subtext mb-0">{categoria}</p>
                                    </Col>
                                    {document.cookie.match('connesso') &&
                                        <>
                                            {likess || likes
                                            ?
                                                <Col className="col-4 mt-3">
                                                    <img  onClick={() => like(posto.id)} src={Heart} className="icon-heart mr-1" width="36" alt="" />
                                                </Col>
                                            :
                                                <Col className="col-4 mt-3">
                                                    <img  onClick={() => like(posto.id)} src={HeartNo} className="icon-heart mr-1" width="36" alt="" />
                                                </Col>
                                            }
                                        </>
                                    }                
                                </Row>
                                <Row className="mx-1">
                                    <hr className="mt-3 mb-1" />
                                    <p className="col">
                                        <span className="distance-card mr-3">{posto.latitudine !== 0 && posto.longitudine !== 0 && geo.lat !== 0 ? getDistanceFormat(posto.latitudine, posto.longitudine, geo.lat, geo.lng) : ""}</span>
                                        <span className="price-card">{posto.costo ? posto.costo.replace(/&euro;/g,"€") : ""}</span>
                                    </p>
                                </Row>
                                </>
                            }   

                            <Row className="text-center mx-3 pb-3 pt-2">
                                {posto.telefono && <Col className="p-0 mr-1"><a href={"tel:"+posto.telefono} className="btn btn-custom btn-second text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col>}
                                {posto.latitudine && posto.longitudine && <Col className="p-0"><a href={"https://maps.google.com/maps?daddr="+posto.latitudine+","+posto.longitudine+"&amp;ll="} target="_blank" rel="noopener noreferrer" className="btn btn-custom btn-second text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col>}
                                {posto.sitoweb && <Col className="p-0 ml-1"><a href={posto.sitoweb} target="_blank" rel="noopener noreferrer" className="btn btn-custom btn-second text-white"><img src={Web} width="20" className="float-left" alt=""/>Website</a></Col>}
                            </Row>
                            
                            {/* {posto.telefono && <Row className="mx-2"><Col className="p-0 mx-2"><a href={"tel:"+posto.telefono} className="btn btn-custom btn-second text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col></Row>}
                            {posto.latitudine && posto.longitudine && <Row className="mx-2"><Col className="p-0 mx-2 my-2"><a href={"https://maps.google.com/maps?daddr="+posto.latitudine+","+posto.longitudine+"&amp;ll="} target="_blank" className="btn btn-custom btn-second text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col></Row>}
                            {posto.sitoweb && <Row className="mx-2"><Col className="p-0 mx-2 mb-2"><a href={posto.sitoweb} target="_blank" className="btn btn-custom btn-second text-white"><img src={Web} width="20" className="float-left" alt=""/>Website</a></Col></Row>}
                            */}
                            {posto.prenota !== '0' /*&& document.cookie.match('connesso')*/ && (
                                <Row className="my-3 mx-2">
                                    <Button onClick={toggleOverlay} className="w-100 mx-2 btn-first">{selector.testo_srv20}</Button>
                                </Row>
                            )}
                            {/* {!document.cookie.match('connesso') && posto.prenota !== '0' &&
                                <Row className="mx-3">
                                    <Link to={{pathname: '/login', query: { route: 'login' } }} className="p-3 btn w-100 text-white font-weight-bold btn-first">{homeData.testo109}</Link>
                                </Row>
                            } */}
                            {props.history.location?.state && props.history.location.state.page === 'itinerary'
                            ?
                                <>
                                </>
                            :
                                <>
                                    {selector.orari.giorni &&
                                        <div className="border-top border-bottom pt-3 pb-3 mt-4 mx-3">
                                            <Row>
                                                <Col className="col-auto d-flex align-items-center">
                                                    <img src={Clock} width="20" className="mr-3" alt=""/>
                                                    <span className="text-schedule ">{selector.orari.label}</span>
                                                </Col>
                                                <Col className="text-right" onClick={toggle} >
                                                    <small className="small">{selector.testo_orari} {!isOpen ? <img src={ChevronDown} alt='' /> : <img src={ChevronUp} alt='' />}</small>
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={isOpen}>
                                                <div className="mt-2">
                                                    {selector.orari.giorni && selector.orari.giorni.map((giorno, index) =>
                                                        <Row className="p-1" key={index}>
                                                            <Col>
                                                                <span className="font-weight-bold">{giorno.giorno}</span>
                                                                <span className="float-right">{giorno.orario}</span>
                                                            </Col>
                                                        </Row>)}
                                                </div>
                                            </Collapse>
                                        </div>
                                    }
                                </>
                            }
                            <p className="text-justify p-3 pb-4">
                                {parse(posto.descrizione)}
                            </p>

                            {props.history.location?.state && props.history.location.state.page === 'itinerary'
                            ?
                                <>
                                </>
                            :
                                <>
                                {/* RECENSIONI W2
                                <div className="little-title mx-3 mb-4">Reviews</div>
                                <div className="mx-3 mt-4 pb-4 border-bottom">
                                    <div className="my-2">
                                        <i className="fas fa-star icon-rating rating-active mr-1"></i>
                                        <i className="fas fa-star icon-rating rating-active"></i>
                                        <i className="fas fa-star icon-rating rating-active mx-1"></i>
                                        <i className="fas fa-star icon-rating rating-active"></i>
                                        <i className="fas fa-star icon-rating mx-1"></i>
                                        <small>4/5</small>
                                        <small className="float-right">18 Aug 2020</small>
                                    </div>
                                    <h6 className="font-weight-bold">Absolute satisfied!</h6>
                                    <span>This is a popular question people ask me. How do you know if I am mastering the art of Deliberate Attraction?
                                    </span>
                                </div>
                                <div className="mx-3 mt-4 pb-4 border-bottom">
                                    <div className="my-2">
                                        <i className="fas fa-star icon-rating rating-active mr-1"></i>
                                        <i className="fas fa-star icon-rating rating-active"></i>
                                        <i className="fas fa-star icon-rating rating-active mx-1"></i>
                                        <i className="fas fa-star icon-rating rating-active"></i>
                                        <i className="fas fa-star icon-rating rating-active mx-1"></i>
                                        <small>5/5</small>
                                        <small className="float-right">15 Aug 2020</small>
                                    </div>
                                    <h6 className="font-weight-bold">DOPE!!</h6>
                                    <span>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </span>
                                </div>
                                <div className="mx-3 mt-4 pb-4 border-bottom">
                                    <div className="my-2">
                                        <i className="fas fa-star icon-rating rating-active mr-1"></i>
                                        <i className="fas fa-star icon-rating rating-active"></i>
                                        <i className="fas fa-star icon-rating mx-1"></i>
                                        <i className="fas fa-star icon-rating"></i>
                                        <i className="fas fa-star icon-rating mx-1"></i>
                                        <small>2/5</small>
                                        <small className="float-right">11 Aug 2020</small>
                                    </div>
                                    <h6 className="font-weight-bold">No way!</h6>
                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </span>
                                </div>
                                <div className="m-3 mt-4 pb-3">
                                    <Button style={{ fontWeight: "700" }} className="mb-2 mr-3 btn-first">Leave A Review</Button>
                                    <Button style={{ fontWeight: "700" }} className="btn-light mb-2">See All Reviews</Button>
                                </div>
                                */}
                                {selector.posti_around.length > 0 &&
                                <div>
                                    <h4 className="m-3 font-weight-bold">{homeData.testo118}</h4>
                                    <div className="testimonial-group container mt-2 mb-4 px-0">
                                        <Row className="text-center" style={{ margin: "0px" }}>
                                            {selector.posti_around.map((posto, index) =>
                                                <div className="col-5 mx-2" key={index}>
                                                    <Link key={index} to={{pathname: "/place/" + posto.id, state: { prevPath: '/place/'+props.match.params.id} }} >
                                                    <div className="card-img-related" style={{ backgroundImage: "url('"+posto.copertina+"')" }}>
                                                        {/* <div className="row m-2 rate-box">
                                                            <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                                            <div className="col-auto p-0">{posto.cuori}</div>
                                                        </div> */}
                                                    </div>
                                                    <h6 className="font-weight-bold text-left mt-2 text-truncate" title={posto.nome}>{posto.nome}</h6>
                                                    </Link>
                                                </div>
                                            )}   
                                        </Row>
                                    </div>
                                </div>
                                }
                                </>
                            }
                        </>
                    }

                    <Modal isOpen={isOverlay} centered={true} scrollable={true}>
                        <div className="p-3" style={{boxShadow: "black 0px -20px 200px 20px"}}>
                                <Row>
                                    <Col>
                                        <h4 className="d-flex m-3 text-left font-weight-normal text-uppercase">{selector.testo_srv20}</h4>
                                    </Col>
                                    <Col className="col-auto">
                                    <span onClick={() => setIsOverlay(false)} className="btn-times text-right m-0 mr-1 pb-5" style={{ color: "#d4d4d5", top: "0", backgroundColor: "transparent" }}>&times;</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mx-3">
                                        <span className="mx-auto text-placeholder">{selector.testo_srv21}</span>
                                        <span className="ml-4 btn-plus ml-0 text-center" onClick={() => setQuantita(quantita === 0 ? 0 : quantita-1)}>-</span>
                                        <span id="quantita" className="mx-3" style={{ fontSize: "24px" }}>
                                            {quantita}
                                        </span>
                                        <span className="btn-plus text-center" onClick={() => setQuantita(quantita+1)}>+</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mx-4">
                                        {!userData.loading && "utente" in userData.data
                                            ?
                                                <>
                                                    <Row>
                                                        <input id="nome" defaultValue={userData.data.utente.nome} className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo98} required />
                                                        <input id="camera" defaultValue={userData.data.utente.camera} type="text" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo162}/>
                                                    </Row>
                                                    <Row>
                                                        <input id="cognome" defaultValue={userData.data.utente.cognome} className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo99} required />
                                                        <input id="email" defaultValue={userData.data.utente.email} className="input-service my-2" style={{ height: "auto" }} placeholder="Email" required />
                                                    </Row>
                                                </>
                                            :
                                                <>
                                                    <Row>
                                                        <input id="nome" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo98} required />
                                                        <input id="camera" type="text" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo162}/>
                                                    </Row>
                                                    <Row>
                                                        <input id="cognome" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo99} required />
                                                        <input id="email" className="input-service my-2" style={{ height: "auto" }} placeholder="Email" required />
                                                    </Row>
                                                </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mx-3">
                                        {homeData.testo128}:
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mx-3">
                                        <input id="ora" type="time" className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setOraDa(e.target.value)}/>
                                    </Col>
                                    <Col className="col-auto text-center pt-2">
                                        -
                                    </Col>
                                    <Col className="d-flex mx-3">
                                        <input type="time" className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setOraA(e.target.value)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mx-3">
                                        Data
                                    </Col>
                                </Row>
                                <Row className="mx-1">
                                    <Col className="col-5">
                                        <input id="data" type="date" max={dataAl} className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setDataDal(e.target.value)}/>
                                    </Col>
                                    <Col className="col text-center pt-2">
                                        -
                                    </Col>
                                    <Col className="col-5">
                                        <input type="date" min={dataDal} className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setDataAl(e.target.value)}/>
                                    </Col>
                                </Row>
                                <Row className='mt-4 mx-3'>
                                    {!postLoading
                                    ?
                                        <Button className="w-100 btn-first font-weight-bold" onClick={() => submitOrder()}>{homeData.testo159}</Button>
                                    :
                                        <Button type="submit" className="w-100 btn-first font-weight-bold">
                                            <Spinner style={{ width: '1rem', height: '1rem' }} />                                
                                        </Button>
                                    }
                                </Row>
                        </div>
                    </Modal>
                </>
            :
                <>
                    <div className="d-flex justify-content-center align-items-center w-100" style={{height: "80vh"}}>
                        <div className="d-flex flex-column align-items-center">
                            {/* <img style={{height:"150px", width:"250px"}} src={Footer} /> */}
                            <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </>
            }

        </Fade>
    )
}

export default Place