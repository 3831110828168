import React from "react";
import { getDistance } from '../../functions/getDistance';
import { useHistory } from 'react-router-dom';
import pinmap from '../Mobile/icons/pinmap2.svg';


function PlaceCardCarousel({homeData, posti, geo}){

    const history = useHistory();
    
    return(
        <>
            <div className="scrollmenu mb-5 showscroll">
                {posti.map((posto, index) =>
                    <div key={index} className="mr-3">
                        <img onClick={() =>history.push("/place/"+posto.id)} src={posto.copertina}  height={200} style={{borderRadius: '4px'}}/>

                        <div className="align-items-center d-flex mt-2" style={{fontSize: '12px'}}>
                            {posto.orari ? 
                            <div className="text-white px-2" style={{background: '#06D6A0', borderRadius: '2px'}}>{homeData.testo181}</div> 
                            : 
                            <div className="text-white px-2" style={{background: '#D4D4D5', borderRadius: '2px'}}>{homeData.testo182}</div> 
                            }
                            <div className="ml-2">{posto.categoria}</div>
                        </div>
                        <div className="row align-items-center d-flex">
                            <div className="col place-text">
                                {posto.nome}
                            </div>
                            <div className="col-auto">
                                {posto.costo ? posto.costo.replace(/&euro;/g,"€") : ""}
                            </div>
                        </div>
                        <div className="row align-items-center d-flex">
                            <div style={{fontSize: '12px'}} className="col">
                                <img width={12} src={pinmap}/> {posto.indirizzo}
                            </div>
                            <div className="col-auto">
                                {posto.latitudine !== 0 && posto.longitudine !== 0 && geo.lat !== 0 &&
                                    <>{Math.round(getDistance(posto.latitudine, posto.longitudine, geo.lat, geo.lng)*10)/10} <span className="ml-1">km</span></>
                                }
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </>
    )
}

export default PlaceCardCarousel;