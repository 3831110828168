//import React, { useEffect } from 'react';
import { Fade } from 'reactstrap'
import { getHomeState } from "../../features/homepageSlice"
import { useSelector } from 'react-redux'
import setCookie from '../../functions/setCookie'
import getCookie from '../../functions/getCookie'
import { useState } from 'react'
import { Link } from 'react-router-dom'

function Cookie() {
    const data = useSelector(getHomeState);
    const [cookie, set] = useState(getCookie('cookie'));

    function saveCookie(){
        setCookie("cookie",true,365)
        set(true)
    }

    return (
        <Fade>
            {!cookie &&
                <div className="cookie-box">
                    <span className="cookie-exit" onClick={() => saveCookie()}>&times;</span>
                    <p className="p-3 pr-4 mb-0">
                        <Link to="cookie.html">
                            {data.testo27}
                        </Link>
                    </p>
                </div>
            }
        </Fade>
    )
}

export default Cookie