import { Link } from 'react-router-dom'

function ListaServizi({homeData}) {
return(
    <div className="row row-cols-2">
        {homeData.servizi && homeData.servizi.length > 0 && homeData.servizi.map((servizio, index) => servizio.evidenza === "1" &&
        <div className='col pb-3 px-1 d-flex justify-content-center'>
            <Link key={index} to={"/service/" + servizio.id} style={{backgroundImage: "url('"+servizio.copertina+"')", borderRadius: '10px',backgroundSize: 'cover'}}  className="box_categoria d-flex justify-content-center">
                <div className="align-self-end text-center" style={{boxShadow: "inset 0px -50px 50px -30px rgba(0,0,0,0.80)", width:"100%", borderRadius: '10px'}}>
                    {servizio.sconto !== "0" &&
                        <div className="circle-text" style={{ fontSize: 'unset', position: 'absolute', right: '5%', top: '-3%', width: '2rem', height: '2rem', background: 'rgb(198, 179, 154)'}}>%</div>
                    }
                    <img src={servizio.icona} width="40" height="40" alt="" />
                    <h4 className="pb-2 card-text text-center text-white">
                        {servizio.nome}
                    </h4>

                </div>
            </Link>
        </div>
        )}
    </div>
    )
}

export default ListaServizi