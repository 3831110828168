import React, { useState } from 'react';
import { Fade, Form, Button, Container } from 'reactstrap'
import Smile from './icons/unsubscribe.png'
import { getHomeState } from '../../features/homepageSlice';
import { useSelector } from 'react-redux'

function Unsubscribe(props) {

    const homeData = useSelector(getHomeState);

    const [email, setEmail] = useState('');
    const [res, setRes] = useState('');

    const master = props.match.params.master;

    const submitForm = async (e) => {
        e.preventDefault()

        let response = await fetch('/auto_act.php?action=unsubscribe&master='+master+'&email='+email, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            },
            body: ""
        })

        const json = await response.json();
        setRes(json);
    }
    return (
        <Fade className="text-center">
            {res 
            ?
                <img src={Smile} alt="" style={{height: '50vh'}}/>
            :
            <Form onSubmit={submitForm}>
                <Container>
                    <span className="text-center d-block">{homeData.testo167}</span>
                    <input onChange={(e) => setEmail(e.target.value)} name="email" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="Email" required />
                    <Button type="submit" className="my-3 btn-first w-100 font-weight-bold">{homeData.testo159}</Button>
                </Container>
            </Form>
            }
        </Fade>
    )
}

export default Unsubscribe