import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getHomepage, getHomeState, getHomeStatus } from '../../features/homepageSlice';
import { getGeolocationState } from '../../features/geolocationSlice';
import { getBootState } from '../../features/bootstrapSlice';
import getCookie from '../../functions/getCookie';
import { getUser, getUserState } from "../../features/userSlice";
import Home from './Home';
import '../../desktop.css'
import { Route, Redirect, useLocation } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import HotelInfo from './HotelInfo';
import Place from './Place';
import Login from './Login';
import Profile from './Profile';
import Favorite from './Favorite';
import Logout from '../Mobile/Logout';
import Checkin from './Checkin';
import ThankyouFastCheckin from '../Mobile/ThankyouFastCheckin';
import ThankyouQuest from '../Mobile/ThankyouQuest';
import Thankyou from './ThankYou';
import Privacy from '../Mobile/Privacy';
import Terms from '../Mobile/Terms';
import Cookie from '../Mobile/Cookie';
import Profilazione from '../Mobile/Profilazione';
import Marketing from '../Mobile/Marketing';
import MarketingThird from '../Mobile/MarketingThird';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AppDesktop(){

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHomepage("/get-homepage"));
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);

    const homeData = useSelector(getHomeState);
    const homeStatus = useSelector(getHomeStatus);
    const geo = useSelector(getGeolocationState);
    const bootData = useSelector(getBootState);
    const userData = useSelector(getUserState);
    let query = useQuery();

   if(!homeStatus.loading){
    
        if(query.get('page') === 'fast'){
            if(query.get('decode') == "") {
                return (
                    <Redirect to={"/"+query.get('master')+"/fast-checkin.html"} />
                )
            }else {
                return (
                    <Redirect to={"/"+query.get('master')+"/"+query.get('decode')+"/fast-checkin.html"} />
                )
            }
        }else if(query.get('page') === 'privacy'){
            return (
                <Redirect to={"/"+query.get('master')+"/privacy.html"} />
            )
        }else if(query.get('page') === 'cookie'){
            return (
                <Redirect to={"/"+query.get('master')+"/cookie.html"} />
            )
        }else if(query.get('page') === 'profiling'){
            return (
                <Redirect to={"/"+query.get('master')+"/profiling.html"} />
            )
        }else if(query.get('page') === 'marketing'){
            return (
                <Redirect to={"/"+query.get('master')+"/marketing.html"} />
            )
        }else if(query.get('page') === 'marketingthird'){
            return (
                <Redirect to={"/"+query.get('master')+"/marketingthird.html"} />
            )
        }else if(query.get('page') === 'info'){
            return (
                <Redirect to={"/info.html"} />
            )
        }

        return(
            <> 
                <BrowserRouter>
                    <Route path ="/" component={Navbar} />
                    <Route exact path="/" render={() => <Home homeData={homeData} homeStatus={homeStatus} geo={geo} bootData={bootData} userData={userData} />} />
                    <Route exact path="/info" render={() => <HotelInfo bootData={bootData} status={homeStatus} homeData ={homeData} />} />
                    <Route exact path="/:master/info" render={() => <HotelInfo bootData={bootData} status={homeStatus} homeData ={homeData} />} />
                    <Route exact path="/place/:id" render={() => <Place homeData={homeData} bootData={bootData} userData={userData} geo={geo}/>} />
                    <Route exact path="/login" render={() => <Login />} />
                    <Route exact path="/service/:id" render={() => <Home homeData={homeData} homeStatus={homeStatus} geo={geo} bootData={bootData} userData={userData} />} />
                    <Route exact path="/profile" render={() => <Profile />} />
                    <Route exact path="/favorite" render={() => <Favorite />} />
                    <Route exact path="/:master/fast-checkin.html" render={() => <Checkin />} />
                    <Route exact path="/:master/:decode/fast-checkin.html" render={() => <Checkin />} />
                    <Route exact path="/thankyoufc" render={() => <ThankyouFastCheckin />} />
                    <Route exact path="/thankyou" render={() => <Thankyou />} />
                    <Route exact path="/thankyouquest" render={() => <ThankyouQuest />} />
                    <Route exact path="/:master/terms.html" render={() => <Terms />} />
                    <Route exact path="/:master/privacy.html" render={() => <Privacy />} />
                    <Route exact path="/:master/cookie.html" render={() => <Cookie />} />
                    <Route exact path="/:master/profiling.html" component={Profilazione} />
                    <Route exact path="/:master/marketing.html" component={Marketing} />
                    <Route exact path="/:master/marketingthird.html" component={MarketingThird} />
                    <Route exact path="/logout" component={Logout} />
                </BrowserRouter>
            </>
        )   
    }else{
        return(
            <div className="d-flex justify-content-center align-items-center w-100" style={{height: '100vh'}}>
                <div className="d-flex flex-column align-items-center">
                    <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
    
}

export default AppDesktop;