import React from 'react';
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getGeolocationState } from '../../features/geolocationSlice'
import { getDistanceFormat } from '../../functions/getDistance'
import { useSelector } from 'react-redux'
import { getHomeState } from '../../features/homepageSlice'
import { getBootStatus } from "../../features/bootstrapSlice"

function Placelist(props) {
    
    const data = useSelector(getHomeState);
    const geo = useSelector(getGeolocationState)
    const bootState = useSelector(getBootStatus);

    return (
        <>
            <div className="testimonial-group container-fluid pt-4 bg-white">
                <div className="d-flex align-items-center">
                    <Col className="col-8 pl-0">
                        <span style={{ fontWeight: "700", fontSize: "calc(0.95em + 1vmin)" }}>{props.sezione.titolo}</span>
                    </Col>
                    <Col className="col-4 text-right">
                        <Link to={{pathname: `/viewall`, query: props}} className="link-color">{data.testo97}</Link>
                    </Col>
                </div>
                <Row className="text-center border-bottom m-0 pb-3">
                    {props.sezione.items && props.sezione.items.length > 0 && props.sezione.items.map((posto, index) => {
                        return(
                            <>
                            {(() => {
                                if(posto.tipo && posto.tipo === "categorie"){
                                    return (
                                        <Link key={posto.id+'_'+index} to={{pathname: `/viewall`, query: {item: { items:posto.posti} }}}  className="col-8 card-custom card-lg p-0 mb-0">
                                            <div className="place-card-img-mobile_v2" style={{width:'200px', backgroundImage: `url('${posto.copertina}')` }}>
                                                {/*<div className="row m-2 rate-box">
                                                    <div className="col-auto pr-1 mx-auto fas fa-star"></div>
                                                    <div className="col-auto p-0">4.5</div>
                                                </div>
                                                <div className="row m-2 rate-box">
                                                    <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                                    <div className="col-auto p-0">{posto.cuori}</div>
                                                </div>*/}
                                            </div>
                                            <p className="mb-1 float-right text-right">
                                                <span className="price-card"></span><br />
                                                <span className="distance-card"></span>
                                            </p>
                                            <h4 className="card-text text-truncate mb-0">{posto.nome}</h4>
                                            <p className="card-subtext mb-0 text-truncate">{posto.categoria}</p>
                                        </Link>
                                    )
                                }else if(posto.tipo && posto.tipo === "itinerari"){
                                    return (
                                        <Link key={posto.id+'_'+index} to={{pathname: props.sezione.label === "tours" ? "/itinerary/" + posto.id: "/place/" + posto.id, state: { prevPath: '/'} }}  className="col-8 card-custom card-lg p-0 mb-0">
                                            <div className="place-card-img-mobile_v2" style={{width:'200px', backgroundImage: `url('${posto.copertina}')` }}>
                                                {/*<div className="row m-2 rate-box">
                                                    <div className="col-auto pr-1 mx-auto fas fa-star"></div>
                                                    <div className="col-auto p-0">4.5</div>
                                                </div>
                                                <div className="row m-2 rate-box">
                                                    <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                                    <div className="col-auto p-0">{posto.cuori}</div>
                                                </div>*/}
                                            </div>
                                            <p className="mb-1 float-right text-right">
                                                <span className="price-card"></span><br />
                                                <span className="distance-card"></span>
                                            </p>
                                            <h4 className="card-text text-truncate mb-0">{posto.nome}</h4>
                                            <p className="card-subtext mb-0 text-truncate">{posto.categoria}</p>
                                        </Link>
                                    )
                                }else {
                                    return ( //posti
                                        <Link key={posto.id+'_'+index} to={{pathname: "/place/" + posto.id, state: { prevPath: '/'} }}  className="col-8 card-custom card-lg p-0 mb-0">
                                            <div className="place-card-img-mobile_v2" style={{width:'200px', backgroundImage: `url('${posto.copertina}')` }}>
                                                {/*<div className="row m-2 rate-box">
                                                    <div className="col-auto pr-1 mx-auto fas fa-star"></div>
                                                    <div className="col-auto p-0">4.5</div>
                                                </div>*/}
                                                {/* posti senza cuore - desio */}
                                                {props.sezione.label === "postinon" && (bootState.data.master === "1325" || bootState.data.master === "1539" || bootState.data.master === "1535" || bootState.data.master === "1537" || bootState.data.master === "1540" || bootState.data.master === "1550")
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                    {/*
                                                    <div className="row m-2 rate-box">
                                                        <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                                        <div className="col-auto p-0">{posto.cuori}</div>
                                                    </div>
                                                    */}
                                                    </>
                                                }
                                            </div>
                                            <p className="mb-1 float-right text-right">
                                                <span className="price-card">{posto.costo ? posto.costo.replace(/&euro;/g,"€") : ""}</span><br />
                                                <span className="distance-card">{posto.latitudine !== 0 && posto.longitudine !== 0 && geo.lat !== 0 ? getDistanceFormat(posto.latitudine, posto.longitudine, geo.lat, geo.lng) : ""}</span>
                                            </p>
                                            <h4 className="card-text text-truncate mb-0">{posto.nome}</h4>
                                            <p className="card-subtext mb-0 text-truncate">{posto.categoria}</p>
                                        </Link>
                                    )
                                }
                            })()}
                            </>
                        )
                    })}
                </Row>
            </div>
        </>
    )
}

export default Placelist