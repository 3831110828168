import GoogleMapReact from "google-map-react";
import MapsPin from "../Mobile/MapsPin";
import { useState } from 'react';


function PlacesMapContainer({ data, places}) {

    //hooks centro della mappa
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    //gestore del centro della mappa
    const onClickMaps = (elmn, childProps) => {
        setLat(parseFloat(elmn.split('-')[0]))
        setLng(parseFloat(elmn.split('-')[1]))
    }

    return(
            <div style={{height:"70vh"}}>
            {data.posto_master &&
                        <GoogleMapReact
                            bootstrapURLKeys={{ key:"AIzaSyAUPWWxtpZHGRnLPjFBF1O2XvYFxG4jxjs"}}
                            defaultCenter={{ lat: parseFloat(data.posto_master.latitudine), lng: parseFloat(data.posto_master.longitudine) }}
                            center={{ lat: lat !== 0 ? lat : parseFloat(data.posto_master.latitudine), lng: lng !== 0 ? lng : parseFloat(data.posto_master.longitudine) }}
                            defaultZoom={12}
                            onChildClick={onClickMaps}
                        >
                {places.map((posto, index) => {
                    return (
                        <MapsPin
                            index={index}
                            key={posto.latitudine + "-" + posto.longitudine + "-" + index}
                            lat={posto.latitudine}
                            lng={posto.longitudine}
                            text={posto.nome}
                        >
                        </MapsPin>
                    )
                })}
                </GoogleMapReact>
            }
    </div>
    )
}

export default PlacesMapContainer;