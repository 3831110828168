//import React, { useState, useRef } from "react";
/* import {Camera} from "react-camera-pro"; */

function CameraShot() {
    //const camera = useRef(null);
    //const [image, setImage] = useState(null);
   

    return (
        <>
            <h1>Camera</h1>
            {/* <Camera ref={camera} />
            <button onClick={() => setImage(camera.current.takePhoto())}>Take photo</button>
            <img src={image} alt='Taken photo' /> */}
        </>
    )
}

export default CameraShot