import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, FormGroup, Input} from 'reactstrap';
import Risposta from './Risposta';
import { setSubAnswers, getSubquestionsState, setAnswers } from '../../features/questSlice';
import { useDispatch, useSelector } from 'react-redux';
import Sottodomanda from './Sottodomanda';

function Domanda(props){

    let index = 0;

    const dispatch = useDispatch();

    const [activeButtons, setActiveButtons] = useState({});

    const subquestions = useSelector(getSubquestionsState);

    useEffect(() =>{
        
        if(props.dotipo === "RV"){
            activeButtons[props.doid] = props.opzione;
        }

    }, []);

    const updateField = e =>{
        
        if(activeButtons[e.target.name] !== ""){
            let elem = document.getElementsByName(e.target.name);

            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === activeButtons[e.target.name]){
                    elem[i].className = "btn-valutazione";
                }
            }

            
            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === e.target.id){
                    elem[i].className = "btn-valutazione active";
                }

            }
            activeButtons[e.target.name] = e.target.id;
        }else{
            let elem = document.getElementsByName(e.target.name);
            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === e.target.id){
                    elem[i].className = "btn-valutazione active";
                }
            }
            activeButtons[e.target.name] = e.target.value;

        }
       
        let answer = {id: e.target.name, vote: e.target.id, qt: e.target.dataset.questiontype}

        switch(e.target.dataset.questiontype){
            case 'RV':
                dispatch(setAnswers({name: "voto"+e.target.name, vote: e.target.id}));
            break;
        }
     
        dispatch(setSubAnswers(answer));
    }

    const updateTextState = e => {
        dispatch(setAnswers({name: e.target.name, vote: e.target.value}));
    }


    switch(props.domanda.tipologia){
        case 'RM':
            return(
            <div>
                <p style={{color: "#727E8C"}}>
                    <b>{props.index+1}.</b> {props.domanda.testo}
                </p>
                <Risposta domanda={props.domanda} />
                {subquestions.map(subquestion => {
                            let index = 0;
                             if(subquestion.id === props.domanda.doid){
                                 index++;
                                return (<Sottodomanda main={props.index+1} index={index} sottodomanda={subquestion.subquestions} />);
                             }
                         })}
                         <hr style={{border: "1px solid #E3E7EA", marginTop: "30px"}}></hr>
            </div>
            );

        case 'RO':
            return(
                <div>
                    <p style={{color: "#727E8C"}}>
                        <b>{props.index+1}.</b> {props.domanda.testo}
                    </p>
                    <Risposta domanda={props.domanda} />
                    {subquestions.map(subquestion => {
                        let index = 0;
                             if(subquestion.id === props.domanda.doid){
                                 index++;
                                return (<Sottodomanda main={props.index+1} index={index} sottodomanda={subquestion.subquestions} />);
                             }
                         })}
                         <hr style={{border: "1px solid #E3E7EA", marginTop: "30px"}}></hr>
                </div>
            );

        case 'RV':
            return(
                    <div>
                        <p style={{color: "#727E8C"}}>
                            <b>{props.index+1}.</b> {props.domanda.testo}
                        </p>
                        <ButtonToolbar style={{verticalAlign: "middle", display:"table", marginTop:"3%"}} >
                            <span style={{display: "table-cell"}} className="text-center text-valutazione">{props.domanda.mintext}</span>
                            <ButtonGroup onClick={updateField} >
                                <Button name={props.domanda.doid} type="button" id="1" data-questiontype="RV" className="btn-valutazione">1</Button>
                                <Button name={props.domanda.doid} type="button" id="2" data-questiontype="RV" className="btn-valutazione">2</Button>
                                <Button name={props.domanda.doid} type="button" id="3" data-questiontype="RV" className="btn-valutazione">3</Button>
                                <Button name={props.domanda.doid} type="button" id="4" data-questiontype="RV" className="btn-valutazione">4</Button>
                                <Button name={props.domanda.doid} type="button" id="5" data-questiontype="RV" className="btn-valutazione">5</Button>
                            </ButtonGroup>
                            <span style={{display: "table-cell"}} className="text-center text-valutazione">{props.domanda.maxtext}</span>
                        </ButtonToolbar>
                         {subquestions.map(subquestion => { 
                              index++;
                             if(subquestion.id === props.domanda.doid){
                                return (<Sottodomanda sottodomanda={subquestion.subquestions} main={props.index+1} index={index} />);
                             }
                         })}
                         <hr style={{border: "1px solid #E3E7EA", marginTop: "30px"}}></hr>
                    </div>
            );

        case 'RL':
            return(
                <div>
                    <p style={{color: "#727E8C"}}>
                        <b>{props.index+1}.</b> {props.domanda.testo}
                    </p>
                    <FormGroup >
                        <Input name={"libero"+props.domanda.doid} onChange={updateTextState} data-questiontype="RL" style={{minHeight: "90px", marginTop: "2%"}} type="textarea" id="exampleText" />
                    </FormGroup>
                </div>
            );
    }
}

export default Domanda;