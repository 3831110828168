import React, { useState } from 'react'
import { Dropdown , DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useSelector } from 'react-redux';
import { getBootState } from '../../features/bootstrapSlice';
import getCookie from '../../functions/getCookie'

function Lingue(props) {
    //gestione lingue
    const [lingua, setLingua] = useState(getCookie('lingua') ? getCookie('lingua') : 'IT');

    const setLinguaF = async (lingua) => {
        
        let response = await fetch('/parser.php?lingua='+lingua, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            },
            body: ""
        })

        window.location.reload(false)
        setLingua(lingua)
    }

    //dropdown lingue
    const [dropdownOpen, setdropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setdropdownOpen(!dropdownOpen);
    }

    const bootdata = useSelector(getBootState);

    if(bootdata.posto_master){
        return (
            <Dropdown nav={true} className="label-lang" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle tag="span" className="label-lang">
                    {lingua}
                </DropdownToggle >
                <DropdownMenu style={{minWidth:"auto"}}>
                    {bootdata.lingue && bootdata.lingue.map((lingua, index) =>
                        <DropdownItem key={index} tag="span" className="p-3 label-lang" onClick={() => setLinguaF(lingua.id)}>
                            <img src={"/dashboard/images/"+lingua.flag} className="mr-2" width="20" alt=""/>
                            <span>{lingua.id}</span>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        )
    }else {
        return null
    }
}

export default Lingue;