import React from 'react';
import { Fade, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getHomeState } from '../../features/homepageSlice';
import ListaServizi from './ListaServizi'


function Servizi(props) {
    const homeData = useSelector(getHomeState);

    return (
        <Fade>
            <div className="mt-2 mb-2">
                <Row className='pl-0 pt-3 pb-3 justify-content-between'>
                    <Col className='col-auto'>
                        <span style={{ fontWeight: "700", fontSize: "calc(0.95em + 1vmin)" }}>{homeData.testo183}</span>
                    </Col>
                    <Col className='col-auto'>
                        <Link to={{pathname:"/allservices", query: props.frompath}} className="link-color">{homeData.testo97}</Link>
                    </Col>
                </Row>

                <ListaServizi homeData={homeData}/>

                {/*<div className='row px-1'>
                    <Link to={{pathname:"/allservices", query: props.frompath}} style={{backgroundColor: 'white', borderRadius: '10px'}}  className="col py-2 d-flex justify-content-center">
                        <div className="align-self-end text-center">
                            <img src={others} width="40" height="40" alt=""/>
                            <h4 className="card-text text-center">{data.testo97}</h4>
                        </div>
                    </Link>
                </div>*/}
            </div>
        </Fade>
    )
}

export default Servizi;
