import React, { useState } from 'react';
import { Col, Row, Collapse } from 'reactstrap'
import ChevronDown from '../Mobile/icons/chevron_down.svg'
import ChevronUp from '../Mobile/icons/chevron_up.svg'
import { useSelector } from 'react-redux';
import { getHomeState } from '../../features/homepageSlice';

function Ordini(props) {

    const homeData = useSelector(getHomeState);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <div className="pb-2">
                <Row>
                    <Col className="text-center pointer" onClick={toggle} >
                        <small className="small">{homeData.testo136} {!isOpen ? <img src={ChevronDown} alt="" /> : <img src={ChevronUp} alt="" />}</small>
                    </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                    <div className="mt-2">
                        {props.menu.map((elemento, index) =>
                        <>
                            <Row className="p-1">
                                <Col>
                                    <span className="font-weight-bold">{elemento.elemento}</span>
                                    <span className="float-right">€ {elemento.totale}</span>
                                </Col>
                            </Row>
                            <Row className="p-1">
                                <Col style={{fontSize: '12px'}}>
                                    <span className="">{elemento.data}</span>
                                    <span className="float-right">{elemento.orario}</span>
                                </Col>
                            </Row>
                        </>
                        )}
                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default Ordini