import { useState } from 'react';
import { Col, Fade, Row } from 'reactstrap';
import Phone from '../Mobile/icons/phone.svg';
import Clock from '../Mobile/icons/clock.svg';
import Chat from '../Mobile/icons/chat.svg';
import Map from '../Mobile/icons/Map.svg';
import GoogleMapReact from 'google-map-react';
import MapsPin from '../Mobile/MapsPin';
import Servizi from './ServicesBar';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import purify from "dompurify";

function HotelInfo({bootData, status, homeData}) {

    const [lat, /*setLat*/] = useState(0);
    const [lng, /*setLng*/] = useState(0);

    const [/*modalLink*/, setModalLink] = useState('');

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };


    return(
        <>
            {!status.loading
            ?
                <Fade>
                    <div style={{backgroundColor: "#F5F5F5"}}>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-height"
                            >
                                        {bootData.gallery.map((src) =>{
                                            return(

                                                <div onClick={() => setModalLink(src)} className="h-100" style={{backgroundImage:'url("'+src+'")' ,  backgroundPosition: "center", backgroundSize: "cover"}} ></div>

                                            )
                                        })}

                        </Carousel>
                            <div className="row m-4">

                                <div className="mt-4 col-8">
                                    <span style={{display: "block", fontSize:"1.6em"}} className="display-4 p-3 m-1" dangerouslySetInnerHTML={{ __html: purify.sanitize(bootData.posto_master.messaggio) }}></span>
                                    <hr style={{border: "1px solid #dee2e6"}}></hr>
                                    <p className="text-justify m-1 p-3" dangerouslySetInnerHTML={{ __html: purify.sanitize(bootData.posto_master.descrizione) }}></p>
                                    <hr style={{border: "1px solid #dee2e6"}}></hr>
                                    <div className='p-3'>
                                        <Servizi homeData={homeData}/>
                                    </div>
                                </div>

                                <div className="col-4 pb-5">
                                    <div className="hotel-info-container bg-white">
                                        <div className="hotel-info-main">
                                            <Row className="text-center mt-3 mr-3 ml-3 ">
                                                {bootData.posto_master.telefono !== "" &&
                                                    <Col className="p-0 mr-2"><a href={"tel:"+bootData.posto_master.telefono} className="btn btn-custom text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col>
                                                }
                                                {bootData.posto_master.email !== "" &&
                                                    <Col className="p-0"><a href={"mailto:"+bootData.posto_master.email} className="btn btn-custom text-white"><img src={Chat} width="20" className="float-left" alt=""/>Email</a></Col>
                                                }
                                                {bootData.posto_master.latitudine !== "0" && bootData.posto_master.longitudine !== "0" &&
                                                    <Col className="p-0 ml-2"><a href={"https://maps.google.com/maps?daddr="+bootData.posto_master.latitudine+","+bootData.posto_master.longitudine+"&amp;ll="} target="_blank" rel="noreferrer" className="btn btn-custom text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col>
                                                }
                                            </Row>
                                            {bootData.posto_master.booking &&
                                                <Row className="m-3">
                                                    <a href={bootData.posto_master.booking} target="_blank" rel="noreferrer" className="col btn btn-bottom-info d-block mx-auto m-0">{homeData.testo171}</a>
                                                </Row>
                                            }
                                            {bootData.posto_master.checkin &&
                                                <div className="border-top border-bottom pt-3 pb-3 ml-3 mr-3">
                                                    <Row>
                                                        <Col className="d-flex align-items-center">
                                                            <img src={Clock} width="20" className="mr-3" alt=""/>
                                                            <span className="text-schedule ">Check-in / Check-out</span>
                                                        </Col>
                                                        <Col className="col-4 d-block text-right">
                                                            <small className="small">{bootData.posto_master.checkin ? bootData.posto_master.checkin : "14:00"} / {bootData.posto_master.checkout ? bootData.posto_master.checkout : "12:00"}</small>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            <Row className="mr-3 ml-3 pb-3 mt-3" style={{height:"200px"}}>
                                                {bootData.posto_master.latitudine !== "0" && bootData.posto_master.longitudine !== "0" &&
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key:"AIzaSyAUPWWxtpZHGRnLPjFBF1O2XvYFxG4jxjs"}}
                                                        defaultCenter={{ lat: parseFloat(bootData.posto_master.latitudine), lng: parseFloat(bootData.posto_master.longitudine) }}
                                                        center={{ lat: lat !== 0 ? lat : parseFloat(bootData.posto_master.latitudine), lng: lng !== 0 ? lng : parseFloat(bootData.posto_master.longitudine) }}
                                                        defaultZoom={12}
                                                    >
                                                        <MapsPin
                                                            key={bootData.posto_master.latitudine + "-" + bootData.posto_master.longitudine}
                                                            lat={bootData.posto_master.latitudine}
                                                            lng={bootData.posto_master.longitudine}
                                                            text={bootData.posto_master.nome}
                                                        >
                                                        </MapsPin>

                                                    </GoogleMapReact>
                                                }
                                            </Row>
                                            <Row className="mt-3 ml-3 text-center mr-3">
                                                <p style={{fontSize: "14px"}}><b>Address:</b> {bootData.posto_master.indirizzo}</p>
                                            </Row>
                                            <Row className="pb-2 mx-0 row-cols-5">
                                                {bootData.posto_master.social.map((social, index) =>
                                                    <>
                                                        {social.details !== "" &&
                                                        <Col className='d-flex justify-content-center mt-3'>
                                                            <a key={index} href={social.details} className="p-0" target="_blank" rel="noopener noreferrer">
                                                                <img src={social.icona} className="services-icon-bottom" alt=""></img><br />
                                                            </a>
                                                        </Col>
                                                        }
                                                    </>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </Fade>
            :
                <></>
            }
        </>
    )
}

export default HotelInfo;