import React from 'react';
import { Container, Fade, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getHomeState } from '../../features/homepageSlice';

function AllServices(props) {
    const data = useSelector(getHomeState);
    //const [size, setSize] = useState(0)

    return (
        <Fade>
            <Row>
                <div className="btn-times">
                    <Link to={{pathname: props.location.query}} className="btn-times-icon d-block text-center">&times;</Link>
                </div>
            </Row>
            
            <Container fluid className="text-center mt-5">
                <h5 className="pb-3">{data.testo170}</h5>
                <Row className="justify-content-center">
                {data.servizi && data.servizi.length > 0 && data.servizi.map((servizio, index) => {
                    return (
                    (index+1) % 2 > 0
                    ?      
                        <Link key={index} to={"/service/" + servizio.id} className="col card-custom">
                            <img src={servizio.icona} className="icon-card-custom float-left" alt=""/>
                            <div style={{display: 'flex', height: '30px', alignItems: 'center', justifyContent: 'center'}}><span className="m-0">{servizio.nome}</span></div>
                            {servizio.sconto !== "0" &&
                                 <div className="circle-text" style={{ fontSize: 'unset', position: 'absolute', right: '-10px', top: '-35%', width: '2rem', height: '2rem', background: '#5a6570'}}>%</div>
                            }
                        </Link> 
                        // <Link key={index} to={"/service/" + servizio.id} style={{backgroundColor: 'white', borderRadius: '4px'}}  className="box_categoria col-4 p-3 px-2 p-0 mb-0 m-1">
                        //     <img src={servizio.icona} width="60" height="60" alt=""/>
                        //     <h4 className="card-text text-center text-truncate">{servizio.nome}</h4>
                        // </Link>
                    :
                    <>
                        <Link key={index} to={"/service/" + servizio.id} className="col card-custom">
                            <img src={servizio.icona} className="icon-card-custom float-left" alt=""/>
                            <div style={{display: 'flex', height: '30px', alignItems: 'center', justifyContent: 'center'}}><span className="m-0">{servizio.nome}</span></div>
                            {servizio.sconto !== "0" &&
                                 <div className="circle-text" style={{ fontSize: 'unset', position: 'absolute', right: '-10px', top: '-35%', width: '2rem', height: '2rem', background: '#5a6570'}}>%</div>
                            }
                        </Link>
                        {/* <Link key={index} to={"/service/" + servizio.id} style={{backgroundColor: 'white', borderRadius: '4px'}}  className="box_categoria col-4 p-3 px-2 p-0 mb-0 m-1">
                            <img src={servizio.icona} width="60" height="60" alt=""/>
                            <h4 className="card-text text-center text-truncate">{servizio.nome}</h4>
                        </Link> */}
                        <div className="w-100"></div>
                    </>
                    )
                }
                )}
                <div style={{boxShadow: 'none'}} className="bg-transparent col card-custom"></div>
                </Row>
            </Container>
        </Fade>
    )
}
export default AllServices