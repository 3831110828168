import React, { useState, useEffect } from 'react';
import { Col, Fade, Row } from 'reactstrap'
import Switch from 'react-ios-switch'
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserState } from '../../features/userSlice'
import getCookie from '../../functions/getCookie'
import { Redirect } from 'react-router-dom'
import { getHomeState } from '../../features/homepageSlice';
import Ordini from './Ordini';
import { useHistory } from 'react-router-dom';

function Profile(props) {
    
    const history = useHistory();
    const homeData = useSelector(getHomeState);

    const [isCheckedPolicy, setIsCheckedPolicy] = useState(true);
    const [isEditName, setIsEditName] = useState(false);
    const [isEditCognome, setIsEditCognome] = useState(false);
    const [isEditEmail, setIsEditEmail] = useState(false);
    const [isEditPhone, setIsEditPhone] = useState(false);
    const [isEditAge, setIsEditAge] = useState(false);
    const [isEditBirth, setIsEditBirth] = useState(false);
    const [isEditNationality, setIsEditNationality] = useState(false);

    // const [Policy, setPolicy] = useState(true);
    // const [Name, setName] = useState("");
    // const [Email, setEmail] = useState("");
    // const [Phone, setPhone] = useState("");
    // const [Age, setAge] = useState("");
    // const [Birth, setBirth] = useState("");
    // const [Nationality, setNationality] = useState("");

    //aggiorno l'utente in caso di refresh
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);
    const userData = useSelector(getUserState);
    
    const logout = () => {
        if(window.confirm("Effettuare il logout?")) {
            history.push("/logout");
            //setCookie('userkey','',-1)
            //dispatch(getUser({ 'userkey': `${getCookie('userkey')}` }))
        }
    }

    if (document.cookie.match('connesso')) {
        return (
        <>
            {!userData.loading && "utente" in userData.data
            ?
                userData.data.utente.nome !== null
                ?
                    <Fade>
                    <div className="row">
                        <div style={{ backgroundColor: "#fff" }} className="col-8">
                            <div>
                                <h4 className="d-block text-center font-weight-normal pt-4">{homeData.testo94}</h4>
                                <Row className="p-0 ml-2 mt-5 mb-4">
                                    <Col>
                                        <small>{homeData.testo98}</small><br />
                                        {isEditName
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="Michael Gore" value={userData.data.utente.nome}/>
                                                <button onClick={() => setIsEditName(!isEditName)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.nome}</span>
                                                {/*<img src={Edit} width={15} onClick={() => setIsEditName(!isEditName)} className="far fa-edit float-right mr-4 icon-edit" alt=""/>*/}
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>{homeData.testo99}</small><br />
                                        {isEditCognome
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="Michael Gore" value={userData.data.utente.cognome}/>
                                                <button onClick={() => setIsEditCognome(!isEditCognome)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.cognome}</span>
                                                {/*<img src={Edit} width={15} onClick={() => setIsEditCognome(!isEditCognome)} className="far fa-edit float-right mr-4 icon-edit" alt=""/>*/}
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>Email</small><br />
                                        {isEditEmail
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="m.gore@gmail.com" value={userData.data.utente.email}/>
                                                <button onClick={() => setIsEditEmail(!isEditEmail)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.email}</span>
                                                <i onClick={() => setIsEditEmail(!isEditEmail)} className="far fa-edit float-right mr-4 icon-edit"></i>
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>{homeData.testo57}</small><br />
                                        {isEditPhone
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="No" value={userData.data.utente.telefono}/>
                                                <button onClick={() => setIsEditPhone(!isEditPhone)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.telefono}</span>
                                                {/*<img src={Edit} width={15} onClick={() => setIsEditPhone(!isEditPhone)} className="far fa-edit float-right mr-4 icon-edit" alt=""/>*/}
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>{homeData.testo122}</small><br />
                                        {isEditAge
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="29" />
                                                <button onClick={() => setIsEditAge(!isEditAge)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.eta}</span>
                                                <i onClick={() => setIsEditAge(!isEditAge)} className="far fa-edit float-right mr-4 icon-edit"></i>
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>{homeData.testo101}</small><br />
                                        {isEditBirth
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="08/12/1991" value={userData.data.utente.compleanno}/>
                                                <button onClick={() => setIsEditBirth(!isEditBirth)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.compleanno}</span>
                                                {/*<img src={Edit} width={15} onClick={() => setIsEditBirth(!isEditBirth)} className="far fa-edit float-right mr-4 icon-edit" alt=""/>*/}
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-4 mb-4">
                                    <Col>
                                        <small>{homeData.testo123}</small><br />
                                        {isEditNationality
                                            ?
                                            <>
                                                <input className="input-edit" type="text" placeholder="Italian" value={userData.data.utente.nazionalita}/>
                                                <button onClick={() => setIsEditNationality(!isEditNationality)} className="float-right mr-3 btn-edit">Save</button>
                                            </>
                                            :
                                            <>
                                                <span>{userData.data.utente.nazionalita}</span>
                                                {/*<img src={Edit} width={15} onClick={() => setIsEditNationality(!isEditNationality)} className="far fa-edit float-right mr-4 icon-edit" alt=""/>*/}
                                            </>}
                                    </Col>
                                </Row>
                                <Row className="p-0 ml-2 mt-5">
                                    <Col className="form-switch">
                                        <span>Marketing Policy</span>
                                        {userData.data.utente.marketing === "1"
                                            ?
                                            <Switch
                                            checked={isCheckedPolicy}
                                            onChange={() => { setIsCheckedPolicy(!isCheckedPolicy) }}
                                            className="d-flex float-right mr-4"
                                            style={{ transform: "scale(0.75)" }}
                                            offColor="#37363b"
                                            disabled
                                            />
                                            :
                                            <Switch
                                            onChange={() => { setIsCheckedPolicy(!isCheckedPolicy) }}
                                            className="d-flex float-right mr-4"
                                            style={{ transform: "scale(0.75)" }}
                                            offColor="#37363b"
                                            disabled
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className="p-4 justify-content-end">
                                    <button className="float-right mr-3 btn-danger btn" onClick={logout}>Logout</button>
                                </Row>
                            </div>
                        </div>
                        <div className="col-4" >
                            <div style={{height: "80.5vh", overflowY: "scroll"}} className="scroll container-fluid">
                            <Ordini userData={userData} homeData={homeData} />
                            </div>
                        </div>
                    </div>
                    </Fade>
                :
                    // <Redirect to="/logout" />
                    <></>
            :
                <></>
            }
        </>
        )
    } else {
        return (
            // <Redirect to="/login" />
            <></>
        )
    }
}

export default Profile