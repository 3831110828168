import { createSlice } from '@reduxjs/toolkit';

export const recentSlice = createSlice({
    name: 'recent',
    initialState: {
        recents: []
    },
    reducers: {
        setRecent(state, action) {
            state.recents = [
                ...state.recents,
                {
                    ...action.payload
                }
                //action.payload.recent
            ]
            
        }
    }
})

export default recentSlice.reducer;
export const { setRecent } = recentSlice.actions
export const getRecentState = state => state.recent.recents;