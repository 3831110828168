import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getItinerario = createAsyncThunk("itinerary", async (endpoint) => {
	const responce = await fetch(endpoint);
	if (!responce.ok) {
		throw Error(responce.statusText);
	}
	const json = await responce.json();
	return json;
})


export const itinerarySlice = createSlice({
	name: 'itinerary',
	initialState: {
		loading: true,
		error: "",
		data: []
    },
	reducers: {
	},
	extraReducers: {
		[getItinerario.pending]: state => { state.loading = true },
		[getItinerario.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getItinerario.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
		}

	}


});

export default itinerarySlice.reducer;

export const getItinerarioState = state => state.itinerary.data;