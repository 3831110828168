import { createSlice } from '@reduxjs/toolkit';

export const geolocationSlice = createSlice({
    name: 'geolocation',
    initialState: {
        coords: {
            lat: 0,
            lng: 0
        }
    },
    reducers: {
        setLat(state, action) {
            state.coords.lat = action.payload.lat
        },
        setLng(state, action) {
            state.coords.lng = action.payload.lng
        },
    }
})

export default geolocationSlice.reducer;
export const { setLat, setLng } = geolocationSlice.actions
export const getGeolocationState = state => state.geolocation.coords;