import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getHomepage = createAsyncThunk("homepage", async (endpoint) =>{
	try{
		const responce = await fetch(endpoint);
		if (!responce.ok) {
			throw Error(responce.statusText);
		}
		const json = await responce.json();
		return json;
	} catch (e) {
		throw Error(e);
	}
})


export const homepageSlice = createSlice({
	name: 'homepage',
	initialState: {
		loading: true,
		error: "",
		data: [],
	},
	reducers: {
	},
	extraReducers : {
		[getHomepage.pending]: state => {state.loading = true},
		[getHomepage.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getHomepage.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
		}

	}


});

export default homepageSlice.reducer;

export const getHomeState = state => state.homepage.data;
export const getHomeStatus = state => state.homepage;