import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Fade, Row } from 'reactstrap'
import { getBootState, getBootStatus } from '../../features/bootstrapSlice'
import { getHomeState } from '../../features/homepageSlice';
import CarousInfo from './CarousInfo';
import Servizi from './Servizi';
import Phone from './icons/phone.svg'
import Chat from './icons/chat.svg'
import Map from './icons/Map.svg'
import Clock from './icons/clock.svg'
import Footer from './icons/We4Guest footer.svg'
import purify from "dompurify";

function Info() {

    const bootData = useSelector(getBootState);
    const status = useSelector(getBootStatus);
    const homeData = useSelector(getHomeState);

    return (
        <>
            {!status.loading
            ?
                <Fade>
                    <div style={{backgroundColor: "#fff"}}>
                        <CarousInfo img={bootData.gallery} />
                        {/* <img className="w-100" src={"/images/copertine/"+bootData.posto_master.copertina} alt=""/> */}
                        <span className="title-info display-4" dangerouslySetInnerHTML={{ __html: purify.sanitize(bootData.posto_master.messaggio) }}></span>
                        <Row className="m-0">
                            <p className="text-justify m-1 p-3" dangerouslySetInnerHTML={{ __html: purify.sanitize(bootData.posto_master.descrizione) }}></p>
                        </Row>
                        <Row className="text-center mr-3 ml-3 pb-3">
                            {bootData.posto_master.telefono !== "" &&
                                <Col className="p-0 mr-2"><a href={"tel:"+bootData.posto_master.telefono} className="btn btn-custom text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col>
                            }
                            {bootData.posto_master.email !== "" &&  
                                <Col className="p-0"><a href={"mailto:"+bootData.posto_master.email} className="btn btn-custom text-white"><img src={Chat} width="20" className="float-left" alt=""/>Email</a></Col>
                            }
                            {bootData.posto_master.latitudine !== "0" && bootData.posto_master.longitudine !== "0" &&
                                <Col className="p-0 ml-2"><a href={"https://maps.google.com/maps?daddr="+bootData.posto_master.latitudine+","+bootData.posto_master.longitudine+"&amp;ll="} target="_blank" rel="noreferrer" className="btn btn-custom text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col>
                            }
                        </Row>
                        {bootData.posto_master.booking &&
                            <a href={bootData.posto_master.booking} target="_blank" rel="noreferrer" className="btn btn-bottom-info d-block mx-3">{homeData.testo171}</a>
                        }
                        {bootData.posto_master.checkin &&
                            <div className="border-top border-bottom pt-3 pb-3 ml-3 mr-3">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <img src={Clock} width="20" className="mr-3" alt=""/>
                                        <span className="text-schedule ">Check-in / Check-out</span>
                                    </Col>
                                    <Col className="col-4 d-block text-right">
                                        <small className="small">{bootData.posto_master.checkin ? bootData.posto_master.checkin : "14:00"} / {bootData.posto_master.checkout ? bootData.posto_master.checkout : "12:00"}</small>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <div className="px-2 my-3">
                            <Servizi frompath="/info"/>
                        </div>
                        <Row className="py-1 mx-0 row-cols-5">
                        {bootData.posto_master.social.map((social, index) => 
                            <>
                                {social.details !== "" &&
                                <Col className='mt-3 justify-content-center d-flex'>
                                    <a key={index} href={social.details} className="p-0" target="_blank">
                                        <img src={social.icona} className="services-icon-bottom" alt=""></img><br />
                                    </a>
                                </Col>
                                }
                            </>
                        )}
                        </Row>
                        <hr/>
                        <div className="p-4">
                            <span>Powered by</span><br />
                            <img src={Footer} className="side-logo" alt=""/>
                        </div>

                    </div>
                </Fade>
            :
                <></>
            }
        </>
    )
}

export default Info;