import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPlace, getFullPlaceState } from "../../features/placeSlice";
import getCookie from '../../functions/getCookie';
import Map from '../Mobile/icons/Map.svg';
import Web from '../Mobile/icons/Web2.svg';
import Phone from '../Mobile/icons/phone.svg';
import Clock from '../Mobile/icons/clock.svg';
import Heart from '../Mobile/icons/heart.svg'
import HeartNo from '../Mobile/icons/Like cuore.svg'
import ChevronDown from '../Mobile/icons/chevron_down.svg';
import ChevronUp from '../Mobile/icons/chevron_up.svg';
import { useHistory, useParams } from "react-router";
import { Fade, Row, Col, Collapse, Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import MapsPin from '../Mobile/MapsPin';
import { ToastContainer, toast } from 'react-toastify';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import PlaceCardCarousel from "./PlaceCardCarousel";
import purify from "dompurify";

toast.configure();

function Place({ homeData, userData, geo }){

    const params = useParams();
    const id = params.id;

    const history = useHistory();

    const [likes, setLikes] = useState(false);
    const [modalLink, setModalLink] = useState('');
    const [postLoading, setPostLoading] = useState(false)

    const closeModal = () => setModalLink('');

    var likess = false;

    var camera = "";
    var nome = "";
    var cognome = "";
    var email = "";
    const [dataDal, setDataDal] = useState('')
    const [dataAl, setDataAl] = useState('')
    const [quantita, setQuantita] = useState(0)
    const [oraDa, setOraDa] = useState('...')
    const [oraA, setOraA] = useState('...')

    const [lat, /*setLat*/] = useState(0);
    const [lng, /*setLng*/] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isOverlay, setIsOverlay] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    //interfaccia prenotazione
    const toggleOverlay = () => {
        // if(document.cookie.match('connesso'))
            setIsOverlay(!isOverlay)
        // else
        //     history.push("/login")
    };


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlace("/get-posto/"+id));
    }, [id]);

    const placeData = useSelector(getFullPlaceState);

    const like = async (posto) => {

        if(getCookie('connesso')) {
            var data = new FormData();
            data.append('action', 'like')
            data.append('posto', posto)
            data.append('ospite', getCookie('userkey'))

            setLikes(!likes)
            likess = like
            
            fetch('/auto_act.php', {
                method: "POST",
                headers: {
                    mode: 'cors'
                },
                body: data
            })
        } else {
            toast.info(homeData.testo108);
        }
        
    }   

    const submitOrder = async () => {
        setPostLoading(true)
        camera = document.getElementById('camera').value;
        nome = document.getElementById('nome').value;
        cognome = document.getElementById('cognome').value;
        email = document.getElementById('email').value;

        if(camera !== "" && email !== "" && dataDal !== "" && oraDa !== "..." && quantita > 0){

            var dataita = dataDal.split('-');
            var dataitaAl = dataAl.split('-');

            if(dataAl !== ""){
                var dataint = dataita[2]+'/'+dataita[1]+'/'+dataita[0]+" - "+dataitaAl[2]+'/'+dataitaAl[1]+'/'+dataitaAl[0]
            }else {
                var dataint = dataita[2]+'/'+dataita[1]+'/'+dataita[0]+" - ..."
            }
            console.log(oraDa, oraA)
                
            let obj = {
                utente: getCookie('userkey'),
                camera: camera,
                nome: nome,
                cognome: cognome,
                email: email,
                giorni: dataint,
                quantita: quantita,
                ore: oraDa + " - " + oraA,
                poid: id
            }

            let response = await fetch('/prenota_act.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj)
            })
            const json = await response.json()
            console.log(json)
            setPostLoading(false)
            //console.log(await response.json())
            history.push("/thankyou")
        }else{
            setPostLoading(false)
            var elm
            if(camera === '') {
                elm = document.getElementById('camera');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("camera").scrollIntoView();
            }else {
                elm = document.getElementById('camera');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(email === '') {
                elm = document.getElementById('email');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("email").scrollIntoView();
            }else {
                elm = document.getElementById('email');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(dataDal === "") {
                elm = document.getElementById('data');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("data").scrollIntoView();
            }else {
                elm = document.getElementById('data');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(oraDa === "...") {
                elm = document.getElementById('ora');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("ora").scrollIntoView();
            }else {
                elm = document.getElementById('ora');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(quantita === 0) {
                elm = document.getElementById('quantita');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("quantita").scrollIntoView();
            }else {
                elm = document.getElementById('quantita');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
        }
    }

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    

    if(!placeData.loading){
        const posto = placeData.data;

        return(
            <>
            <Modal style={{width: "100%", height: "100%"}} centered  isOpen={modalLink}>
                <ModalHeader toggle={closeModal}>
                </ModalHeader>
                <ModalBody>
                    <img className="img-fluid" src={modalLink} alt=""/>
                </ModalBody>
            </Modal>
            <ToastContainer position="top-right" autoClose={5000} />
            <Fade>
                <div style={{backgroundColor: "#F5F5F5"}}>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-height"
                        >
                                    {posto.photos.map((src, index) =>{
                                        return(

                                            // <div onClick={() => setModalLink(src)}  className="h-100" style={{backgroundImage:'url("'+src+'")' ,  backgroundPosition: "center", backgroundSize: "cover"}} ></div>
                                            <div key={index} className="h-100" style={{backgroundImage:'url("'+src+'")' ,  backgroundPosition: "center", backgroundSize: "cover"}} ></div>

                                        )
                                    })}
                        
                    </Carousel>
                    <div className="card card-body">
                        <div className="row">
                            <div className="col-8">
                                <div className="row mt-3">
                                    <div className="col-8">
                                            <span style={{display: "block", fontSize:"1.6em"}} className="display-4 pt-3 m-1">{posto.posto.nome}</span>
                                            <p className="card-subtext mb-0  ml-1">{posto.categorie[0].nome}</p>
                                    </div>
                                    {}
                                    {document.cookie.match('connesso') &&
                                        <>
                                        {likess || likes
                                            ?
                                            <div className="d-flex">
                                                <img  onClick={() => like(posto.posto.id)} src={Heart} className="icon-heart mr-1" width="36" alt="" />
                                            </div>
                                            :
                                            <div className="d-flex">
                                                <img  onClick={() => like(posto.posto.id)} src={HeartNo} className="icon-heart mr-1" width="36" alt="" />
                                            </div>
                                        }
                                        </>
                                    }
                            </div>

                                <hr style={{border: "1px solid #dee2e6"}}></hr>
                                <p className="text-justify pt-3 pb-3" dangerouslySetInnerHTML={{ __html: purify.sanitize(posto.posto.descrizione) }}></p>
                                <hr style={{border: "1px solid #dee2e6"}}></hr>
                                {posto.posti_around.length > 0 &&
                                <Row className="m-0">
                                    <h4 className="font-weight-bold m-1 mb-4">{homeData.testo118}</h4>
                                </Row>
                                }

                                <PlaceCardCarousel homeData={homeData} posti={posto.posti_around} geo={geo}/>
                            </div>
                            <div className="col-4 pb-5">
                                <div className="hotel-info-container bg-white">
                                    <div className="hotel-info-main">
                                    {posto.posto.prenota !== '0' /*&& document.cookie.match('connesso')*/ && (
                                            <Row className="my-3 mx-2">
                                                <Button onClick={toggleOverlay} className="w-100 mx-2 btn-first">{placeData.data.testo_srv20}</Button>
                                            </Row>
                                    )}
                                    {/* {!document.cookie.match('connesso') && posto.posto.prenota !== '0' &&
                                        <Row className="mx-3">
                                            <Link to={{pathname: '/login', query: { route: 'login' } }} className="p-3 btn w-100 text-white font-weight-bold btn-first">{homeData.testo109}</Link>
                                        </Row>
                                    } */}
                                        <Row className="text-center mt-3 mr-3 ml-3 pb-3">
                                            {posto.posto.telefono &&
                                                <Col className="p-0 mr-2"><a href={"tel:"+posto.posto.telefono} className="btn btn-custom text-white"><img src={Phone} width="20" className="float-left" alt=""/>{homeData.testo124}</a></Col>
                                            }
                                            {posto.posto.latitudine &&  posto.posto.longitudine &&
                                                <Col className="p-0"><a href={"https://maps.google.com/maps?daddr="+posto.posto.latitudine+","+posto.posto.longitudine+"&amp;ll="} target="_blank" rel="noreferrer" className="btn btn-custom text-white"><img src={Map} width="20" className="float-left" alt=""/>{homeData.testo107}</a></Col>
                                            }
                                            {posto.posto.sitoweb &&
                                                <Col className="p-0 ml-2"><a href={posto.posto.sitoweb} target="_blank" rel="noreferrer" className="btn btn-custom text-white"><img src={Web} width="20" className="float-left" alt=""/>Website</a></Col>
                                            }
                                        </Row>
                                        {/*bootData.posto_master.booking &&
                                            <a href={bootData.posto_master.booking} target="_blank" rel="noreferrer" className="btn btn-bottom-info d-block mx-auto m-0">{homeData.testo171}</a>
                                        */}
                                        <div className="border-top border-bottom pt-3 pb-3 ml-3 mr-3">
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <img src={Clock} width="20" className="mr-3" alt=""/>
                                                    <span className="text-schedule ">{posto.orari.label}</span>
                                                </Col>
                                                <Col className="col-4 d-block text-right pointer" onClick={toggle}>
                                                    <small className="small">{posto.testo_orari} {!isOpen ?  <img src={ChevronDown} alt=""/> : <img src={ChevronUp} alt=""/>}</small>
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={isOpen}>
                                                <div className="mt-2">
                                                    {posto.orari.giorni && posto.orari.giorni.map((giorno) =>
                                                        <Row className="p-1">
                                                            <Col>
                                                                {giorno.sel == "ok"
                                                                ?
                                                                    <>
                                                                        <span className="font-weight-bold">{giorno.giorno}</span>
                                                                        <span className="float-right">{giorno.orario}</span>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <span>{giorno.giorno}</span>
                                                                        <span className="float-right">{giorno.orario}</span>
                                                                    </>
                                                                }
                                                            </Col>
                                                        </Row>)}
                                                </div>
                                            </Collapse>
                                        </div>
                                        <Row className="mr-3 ml-3 pb-3 mt-3" style={{height:"200px"}}>
                                            {posto.posto.latitudine  && posto.posto.longitudine &&
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key:"AIzaSyAUPWWxtpZHGRnLPjFBF1O2XvYFxG4jxjs"}}
                                                    defaultCenter={{ lat: parseFloat(posto.posto.latitudine), lng: parseFloat(posto.posto.longitudine) }}
                                                    center={{ lat: lat !== 0 ? lat : parseFloat(posto.posto.latitudine), lng: lng !== 0 ? lng : parseFloat(posto.posto.longitudine) }}
                                                    defaultZoom={14}
                                                >
                                                    <MapsPin
                                                        key={posto.posto.latitudine + "-" +posto.posto.longitudine}
                                                        lat={posto.posto.latitudine}
                                                        lng={posto.posto.longitudine}
                                                        text={posto.posto.nome}
                                                    >
                                                    </MapsPin>
                                                </GoogleMapReact>
                                            }
                                        </Row>
                                        <Row className="mt-3 ml-3 mr-3">
                                            <p style={{fontSize: "14px"}}><b>Address:</b> {posto.posto.indirizzo}</p>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={isOverlay} centered={true} scrollable={true}>
                    <ModalHeader toggle={toggleOverlay}>
                        <h4 className="d-flex font-weight-normal">{placeData.data.testo_srv20}</h4>
                    </ModalHeader>
                    <ModalBody className="p-0">
                        <div className="p-3 bg-white text-center" style={{boxShadow: "black 0px -20px 200px 20px"}}>
                            <Col className="mt-3">
                                <Row className="mt-4">
                                    <Col className="d-flex align-items-center">
                                        <span className="text-placeholder">{placeData.data.testo_srv21}</span>
                                        <span className="m-0 ml-5 btn-plus text-center" onClick={() => setQuantita(quantita === 0 ? 0 : quantita-1)}>-</span>
                                        <span id="quantita" className="mx-3" style={{ fontSize: "24px" }}>
                                            {quantita}
                                        </span>
                                        <span className="btn-plus m-0 text-center" onClick={() => setQuantita(quantita+1)}>+</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4">
                                        {!userData.loading && "utente" in userData.data && camera === ""
                                        ?
                                            <>
                                                <input id="camera" defaultValue={userData.data.utente.camera} className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={placeData.data.testo_srv13} />
                                                <input id="nome" defaultValue={userData.data.utente.nome} className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo98} required />
                                                <input id="cognome" defaultValue={userData.data.utente.cognome} className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo99} required />
                                                <input id="email" defaultValue={userData.data.utente.email} className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="Email" required />
                                            </>
                                        :
                                            <>
                                                <input id="camera" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={placeData.data.testo_srv13} />
                                                <input id="nome" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo98} required />
                                                <input id="cognome" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo99} required />
                                                <input id="email" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="Email" required />
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mb-1">
                                        {homeData.testo128}:
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col className="d-flex">
                                        <input id="ora" type="time" className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setOraDa(e.target.value)}/>
                                    </Col>
                                    <Col className="col-1 text-center pt-2">
                                        -
                                    </Col>
                                    <Col className="d-flex">
                                        <input type="time" className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setOraA(e.target.value)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex mt-4 mb-1">
                                        Data:
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <input id="data" type="date" max={dataAl} className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setDataDal(e.target.value)}/>
                                    </Col>
                                    <Col className="col-1 text-center pt-2">
                                        -
                                    </Col>
                                    <Col>
                                        <input type="date" min={dataDal} className="input-service" style={{ borderColor: "#f5f5f5" }} placeholder="Seleziona una data" onChange={(e) => setDataAl(e.target.value)}/>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    {!postLoading
                                    ?
                                        <Button className="w-100 btn-first font-weight-bold" onClick={() => submitOrder()}>{homeData.testo159}</Button>
                                    :
                                        <Button type="submit" className="w-100 btn-first font-weight-bold">
                                            <Spinner style={{ width: '1rem', height: '1rem' }} />                                
                                        </Button>
                                    }
                                </Row>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal>

            </Fade>
            </>
        )
    }else{
        return(
            <div></div>
        )
    }
    
}

export default Place;