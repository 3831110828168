import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getBootstrap = createAsyncThunk("bootstrap", async (endpoint) => {
	const response = await fetch(endpoint);
	if (!response.ok) {
		throw Error(response.statusText);
	}
	const json = await response.json();
	return json;
})

export const bootstrapSlice = createSlice({
	name: 'bootstrap',
	initialState: {
		loading: true,
		error: "",
		data: [],
	},
	reducers: {
	},
	extraReducers: {
		[getBootstrap.pending]: state => { state.loading = true },
		[getBootstrap.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getBootstrap.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
		}

	}


});

export default bootstrapSlice.reducer;

export const getBootState = state => state.bootstrap.data;

export const getBootStatus = state => state.bootstrap;