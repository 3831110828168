import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getUser = createAsyncThunk("user", async (userkey) => {
    try {
        const response = await fetch("/get-user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userkey)
        });
        const json = await response.json();
        return json;
    } catch (e) {
        throw Error(e);
    }
})


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: true,
        error: "",
        data: [],
    },
    reducers: {
        logout(state, action) {
            
            state.data = []
        }
    },
    extraReducers: {
        [getUser.pending]: state => { state.loading = true },
        [getUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.data = action.payload;
        }
    }
});

export default userSlice.reducer;

export const { logout } = userSlice.actions

export const getUserState = state => state.user;

